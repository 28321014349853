import React, { useEffect, useState } from "react";
import './Demo.css';
import axios from "axios";
import Links from "../../Data/Links";
import CreateRow from "../../component/Budget/CreateRow";
import Cookies from 'js-cookie';
import DealerYearMonthSelector from "../../component/Budget/DealerYearMonthSelector";
import { store, updateTotalBudget, updateTotalOfCPHrsRo } from "../../redux";

const months = [
  'Sep',
  'Oct',
  'Nov',
  'Dec',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
]

// Code Splitting for future

const RowTotal = ({ total }) => {

  let userData;
  if (Cookies.get('user_data')) {
    userData = JSON.parse(Cookies.get('user_data'));
  }

  if (!total) {
    total = {}
  }

  let totalStoreGross = parseInt(total.new_retail_front_GP ? total.new_retail_front_GP : 0) +
    parseInt(total.new_retail_back_GP ? total.new_retail_back_GP : 0) +
    parseInt(total.used_retail_front_GP ? total.used_retail_front_GP : 0) +
    parseInt(total.used_retail_back_GP ? total.used_retail_back_GP : 0) +
    parseInt(total.service_total_GP ? total.service_total_GP : 0) +
    parseInt(total.parts_total_GP ? total.parts_total_GP : 0);

  if (isNaN(totalStoreGross) || totalStoreGross === 'NaN' || totalStoreGross === 'Infinity' || totalStoreGross === null) {
    totalStoreGross = '0'
  }

  useEffect(() => {
    store.dispatch(updateTotalBudget({ totalBudget: totalStoreGross }));
  }, [totalStoreGross]);

  let service_CP_hours = total.service_CP_hours ? parseFloat(total.service_CP_hours) : 0;
  let service_CP_ROs = total.service_CP_ROs ? parseFloat(total.service_CP_ROs) : 0;
  let totalCPHrsRo = (service_CP_hours / service_CP_ROs).toFixed(2);
  if (totalCPHrsRo === "NaN" || totalCPHrsRo === 'Infinity') {
    totalCPHrsRo = 0
  }
  totalCPHrsRo = parseFloat(totalCPHrsRo);

  useEffect(() => {
    store.dispatch(updateTotalOfCPHrsRo({ serviceCPhrsDivideRO: totalCPHrsRo }));
  }, [totalCPHrsRo])

  return (
    <tr>
      {userData.roles !== "normal" && userData.roles !== "Radmin" && <> <td className="auto-column"></td>
        <td className="auto-column"></td></>}
      <td className="auto-column">Total</td>
      <td className="auto-column">{total.new_retail_delivered ? total.new_retail_delivered : 0}</td>
      <td className="auto-column">${total.new_retail_front_GP ? parseInt(total.new_retail_front_GP)?.toLocaleString() : 0}</td>
      <td className="auto-column">${total.new_retail_back_GP ? parseInt(total.new_retail_back_GP)?.toLocaleString() : 0}</td>
      <td className="auto-column">{total.new_CSI ? parseInt(total.new_CSI)?.toLocaleString() : 0}</td>
      <td className="auto-column">{total.used_retail_delivered ? total.used_retail_delivered : 0}</td>
      <td className="auto-column">${total.used_retail_front_GP ? total.used_retail_front_GP?.toLocaleString() : 0}</td>
      <td className="auto-column">${total.used_retail_back_GP ? total.used_retail_back_GP?.toLocaleString() : 0}</td>
      <td className="auto-column">${total.service_sales ? total.service_sales?.toLocaleString() : 0}</td>
      <td className="auto-column">${total.service_total_GP ? total.service_total_GP?.toLocaleString() : 0}</td>
      <td className="auto-column">{total.service_total_hours ? total.service_total_hours : 0}</td>
      <td className="auto-column">${total.service_CSI ? total.service_CSI?.toLocaleString() : 0}</td>
      <td className="auto-column">{total.service_CP_hours ? total.service_CP_hours : 0}</td>
      <td className="auto-column">{total.service_CP_ROs ? total.service_CP_ROs : 0}</td>
      <td className="auto-column">{totalCPHrsRo ? totalCPHrsRo.toFixed(2) : 0}</td>
    
  
      {/* <td className="auto-column" >{total.service_hours_available ? total.service_hours_available.toLocaleString() : 0}</td>
      <td className="auto-column" >{total.service_hours_sold ? total.service_hours_sold.toLocaleString() : 0}</td>
  
      <td className="auto-column" >{total.service_no_of_tech ? total.service_no_of_tech.toLocaleString() : 0}</td>
      <td className="auto-column" >{total.service_journeyman ? total.service_journeyman.toLocaleString() : 0}</td>
      <td className="auto-column" >{total.service_apprentice ? total.service_apprentice.toLocaleString() : 0}</td>
      <td className="auto-column" >{total.service_advisors_needed ? total.service_advisors_needed.toLocaleString() : 0}</td> */}


      <td className="auto-column">{total.parts_sales ? total.parts_sales.toLocaleString() : 0}</td>
      <td className="auto-column">${total.parts_total_GP ? total.parts_total_GP?.toLocaleString() : 0}</td>
      <td className="auto-column">{total.appt_made ? total.appt_made : 0}</td>
      <td className="auto-column" >{total.booked ? total.booked : 0}</td>
      <td className="auto-column">{total.appt_show ? total.appt_show : 0}</td>
      <td className="auto-column">{total.store_visit ? total.store_visit : 0}</td>
  
      <td className="auto-column">{total.internet_lead ? total.internet_lead : 0}</td>
      <td className="auto-column" >{total.fresh_up ? total.fresh_up : 0}</td>
      <td className="auto-column" >{total.phone_up ? total.phone_up : 0}</td>
      <td className="auto-column">{total.lead ? total.lead : 0}</td>
      <td className="auto-column" >{total.sales_staff ? total.sales_staff : 0}</td>
   
      {/* <td className="auto-column" >{total.no_show ? total.no_show : 0}</td> */}
 
      <td className="auto-column">${totalStoreGross?.toLocaleString()}</td>
    </tr>
  )

}

const Budget = ({ props }) => {
  const [stores, setStores] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
  const [budgetData, setBudgetData] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");

  const [filterVal, setFilterVal] = useState({
    store_id: 2,
    store_name: 'Acura',
    division: 'Auto',
    company: 'FFUN',
    year: 'FY25'
  });

  let userData;
  if (Cookies.get('user_data')) {
    userData = JSON.parse(Cookies.get('user_data'));
  }
  useEffect(() => {
    let storeObj = {};
    stores.length > 0 && stores?.map(i => {
      if (i.division === "Auto") {
        storeObj[i.id] = i.name;
      }
    });
    setStores(storeObj);
    setFilterVal({
      store_id: Object.keys(storeObj)[0],
      store_name: Object.values(storeObj)[0],
      division: "Auto",
      company: 'FFUN',
      year: 'FY25',
    })
  }, []);

  useEffect(() => {
    loadData();
  }, [filterVal])

  const loadData = (filterData = null) => {
    if (filterData) {
      setFilterVal(filterData);
    }
    axios.post(Links.getYearlyFilteredBudget, filterVal)
      .then(res => {
        setBudgetData(res.data.budget)
      }).catch(err => {
        console.log(err);
      });
  }

  let total = {
    new_retail_delivered: 0,
    new_retail_front_GP: 0,
    new_retail_back_GP: 0,
    new_CSI: 0,
    used_retail_delivered: 0,
    used_retail_front_GP: 0,
    used_retail_back_GP: 0,
    service_total_hours: 0,
    service_CP_hours: 0,
    service_CP_ROs: 0,
    service_CP_hrs_RO: 0,
    service_total_GP: 0,
    service_sales: 0,
    service_CSI: 0,
    service_hours_sold: 0,
    service_hours_available: 0,
    service_no_of_tech: 0,
    service_booked_hours: 0,
    service_units_shipped: 0,
    parts_sales: 0,
    parts_total_GP: 0,
    appt_made: 0,
    appt_show: 0,
    store_visit: 0,
    lead: 0,
    sales_staff: 0,
    booked: 0,
    no_show: 0,
    phone_up: 0,
    fresh_up: 0,
    internet_lead:0,
    service_journeyman:0,
    service_apprentice:0,
    service_advisors_needed:0
  };

  return (
    <div style={{ margin: "2rem 2rem 2rem 0rem" }}>
      <DealerYearMonthSelector stores={stores} filterVal={filterVal} setFilterVal={setFilterVal} loadData={() => loadData()} />

      <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
        <thead>
          <tr className="text-center">
            {userData.roles !== "normal" && userData.roles !== "Radmin" &&
              <>
                <th   className="auto-header-top" colSpan="2" style={{ "background-color": "#e9ecef" }} ></th>
              </>
            }
            <th   className="auto-header-top" style={{ "background-color": "#e9ecef" }} ></th>
            <th   className="auto-header-top" colSpan="4" style={{ "background-color": "orange" }} >New Vehicles</th>
            <th   className="auto-header-top" colSpan="3" style={{ "background-color": "#a2c1a2" }}>Used Vehicles</th>
            <th   className="auto-header-top" colSpan="7" style={{ "background-color": "#b1d5dd" }}>Service</th>
            <th   className="auto-header-top" colSpan="2" style={{ "background-color": "#8b8bb5", "color": "#ffffff" }}>Parts</th>
            <th    className="auto-header-top" colSpan="9"  style={{ "background-color": "#b38bb5" }}>Sales</th>
            <th   className="auto-header-top" style={{ "background-color": "orange" }}></th>
          </tr>
          <tr>
            {userData.roles !== "normal" && userData.roles !== "Radmin" &&
              <>
                <th   className="auto-header" scope="col" style={{ "background-color": "#e9ecef" }} ></th>
                <th   className="auto-header" scope="col" style={{ "background-color": "#e9ecef" }} ></th>
              </>
            }
            <th scope="col"   className="auto-header" style={{ "background-color": "#e9ecef" }} >Month</th>
            <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>Retail Deliv`d</th>
            <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>Retail Front GP</th>
            <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>Retail Back GP</th>
            <th className="auto-header" scope="col" style={{ "background-color": "orange" }}>CSI</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Retail Deliv`d</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Retail Front GP</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#a2c1a2" }}>Retail Back GP</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Sales</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total GP</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Hours</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CSI</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP Hours</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP RO's</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>CP Hrs/RO</th>
{/*        
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Hours Avail</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Total Flag Hours</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>No of Technicians</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Journeyman Needed</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Apprentice Needed</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Advisors Needed</th> */}
            {/* <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Booked Hours</th> */}
            {/* <th className="auto-header" scope="col" style={{ "background-color": "#b1d5dd" }}>Units Shipped</th> */}
            <th className="auto-header" scope="col" style={{ "background-color": "#8b8bb5" }}>Sales</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#8b8bb5" }}>Total GP</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Made</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Confirmed</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Appts Shown</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Store Visits</th>
         
            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Int Leads</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>F/Up</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Phone Up</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Total Leads</th>
            <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>Sales Staff</th>
         
            {/* <th className="auto-header" scope="col" style={{ "background-color": "#b38bb5" }}>No Show</th> */}
            <th scope="col"   className="auto-header" style={{ "background-color": "orange" }}>Total Store Gross</th>

          </tr>
        </thead>
        <tbody>
          {
            months.map((i, n) => {
              total.new_retail_delivered = (budgetData[i] && budgetData[i].new_retail_delivered !== '') ? total.new_retail_delivered + parseInt(budgetData[i].new_retail_delivered) : total.new_retail_delivered;
              total.new_retail_front_GP = (budgetData[i] && budgetData[i].new_retail_front_GP !== '') ? total.new_retail_front_GP + parseInt(budgetData[i].new_retail_front_GP) : total.new_retail_front_GP;
              total.new_retail_back_GP = (budgetData[i] && budgetData[i].new_retail_back_GP !== '') ? total.new_retail_back_GP + parseInt(budgetData[i].new_retail_back_GP) : total.new_retail_back_GP;
              total.new_CSI = (budgetData[i] && budgetData[i].new_CSI !== '') ? total.new_CSI + parseInt(budgetData[i].new_CSI) : total.new_CSI;
              total.used_retail_delivered = (budgetData[i] && budgetData[i].used_retail_delivered !== '') ? total.used_retail_delivered + parseInt(budgetData[i].used_retail_delivered) : total.used_retail_delivered;
              total.used_retail_front_GP = (budgetData[i] && budgetData[i].used_retail_front_GP !== '') ? total.used_retail_front_GP + parseInt(budgetData[i].used_retail_front_GP) : total.used_retail_front_GP;
              total.used_retail_back_GP = (budgetData[i] && budgetData[i].used_retail_back_GP !== '') ? total.used_retail_back_GP + parseInt(budgetData[i].used_retail_back_GP) : total.used_retail_back_GP;
              total.service_total_hours = (budgetData[i] && budgetData[i].service_total_hours !== '') ? total.service_total_hours + parseInt(budgetData[i].service_total_hours) : total.service_total_hours;
              total.service_CP_hours = (budgetData[i] && budgetData[i].service_CP_hours !== '') ? total.service_CP_hours + parseInt(budgetData[i].service_CP_hours) : total.service_CP_hours;
              total.service_CP_ROs = (budgetData[i] && budgetData[i].service_CP_ROs !== '') ? total.service_CP_ROs + parseInt(budgetData[i].service_CP_ROs) : total.service_CP_ROs;
              total.service_CP_hrs_RO = (budgetData[i] && budgetData[i].service_CP_hrs_RO !== '') ? total.service_CP_hrs_RO + parseInt(budgetData[i].service_CP_hrs_RO) : total.service_CP_hrs_RO;
              total.service_total_GP = (budgetData[i] && budgetData[i].service_total_GP !== '') ? total.service_total_GP + parseInt(budgetData[i].service_total_GP) : total.service_total_GP;
              total.service_sales = (budgetData[i] && budgetData[i].service_sales !== '') ? total.service_sales + parseInt(budgetData[i].service_sales) : total.service_sales;
              total.service_CSI = (budgetData[i] && budgetData[i].service_CSI !== '') ? total.service_CSI + parseInt(budgetData[i].service_CSI) : total.service_CSI;
            
              // total.service_hours_sold = (budgetData[i] && budgetData[i].service_hours_sold >0) ? total.service_hours_sold + parseInt(budgetData[i].service_hours_sold) : total.service_hours_sold;
              // total.service_hours_available = (budgetData[i] && budgetData[i].service_hours_available >0) ? total.service_hours_available + parseInt(budgetData[i].service_hours_available) : total.service_hours_available;
              // total.service_no_of_tech = (budgetData[i] && budgetData[i].service_no_of_tech >0) ? total.service_no_of_tech + parseInt(budgetData[i].service_no_of_tech) : total.service_no_of_tech;
              total.service_booked_hours = (budgetData[i] && budgetData[i].service_booked_hours >0) ? total.service_booked_hours + parseInt(budgetData[i].service_booked_hours) : total.service_booked_hours;
              total.service_units_shipped = (budgetData[i] && budgetData[i].service_units_shipped >0) ? total.service_units_shipped + parseInt(budgetData[i].service_units_shipped) : total.service_units_shipped; 

              // total.service_journeyman = (budgetData[i] && budgetData[i].service_journeyman > 0) ? total.service_journeyman + parseInt(budgetData[i].service_journeyman) : total.service_journeyman;
              // total.service_apprentice = (budgetData[i] && budgetData[i].service_apprentice > 0) ? total.service_apprentice + parseInt(budgetData[i].service_apprentice) : total.service_apprentice;
              // total.service_advisors_needed = (budgetData[i] && budgetData[i].service_advisors_needed > 0) ? total.service_advisors_needed + parseInt(budgetData[i].service_advisors_needed) : total.service_advisors_needed;

          
              total.parts_sales = (budgetData[i] && budgetData[i].parts_sales  > 0) ? total.parts_sales + parseInt(budgetData[i].parts_sales) : total.parts_sales;
              total.parts_total_GP = (budgetData[i] && budgetData[i].parts_total_GP  > 0) ? total.parts_total_GP + parseInt(budgetData[i].parts_total_GP) : total.parts_total_GP;
              total.appt_made = (budgetData[i] && budgetData[i].appt_made  > 0) ? total.appt_made + parseInt(budgetData[i].appt_made) : total.appt_made;
              total.appt_show = (budgetData[i] && budgetData[i].appt_show  > 0) ? total.appt_show + parseInt(budgetData[i].appt_show) : total.appt_show;
              total.store_visit = (budgetData[i] && budgetData[i].store_visit  > 0) ? total.store_visit + parseInt(budgetData[i].store_visit) : total.store_visit;
              total.lead = (budgetData[i] && budgetData[i].lead  > 0) ? total.lead + parseInt(budgetData[i].lead) : total.lead;
              total.internet_lead = (budgetData[i] && budgetData[i].internet_lead  > 0) ? total.internet_lead + parseInt(budgetData[i].internet_lead) : total.internet_lead;

              total.sales_staff= (budgetData[i] && budgetData[i].sales_staff >0) ? total.sales_staff + parseInt(budgetData[i].sales_staff) : total.sales_staff;
              total.booked= (budgetData[i] && budgetData[i].booked >0) ? total.booked + parseInt(budgetData[i].booked) : total.booked;
              total.no_show= (budgetData[i] && budgetData[i].no_show >0) ? total.no_show + parseInt(budgetData[i].no_show) : total.no_show;
              total.phone_up= (budgetData[i] && budgetData[i].phone_up>0) ? total.phone_up + parseInt(budgetData[i].phone_up) : total.phone_up;
              total.fresh_up= (budgetData[i] && budgetData[i].fresh_up >0) ? total.fresh_up + parseInt(budgetData[i].fresh_up) : total.fresh_up;
              total.lead = total.internet_lead+total.phone_up+total.fresh_up;
            

              return <CreateRow row={budgetData} userData={userData} month={i} filterData={filterVal} loadData={() => loadData()} />
            })
          }
          <RowTotal total={total} />
        </tbody>
      </table>
    </div>
  );
};

export default Budget