import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const years = [
    'FY20',
    'FY21',
    'FY22',
    'FY23',
    'FY25',
    'FY25'
]

const DealerYearMonthSelector = ({ stores, filterVal, setFilterVal, loadData }) => {

    const handleChange = (field, event) => {
        if (field === 'store_name') {
            setFilterVal({ ...filterVal, [field]: event.target.value, 'store_id': Object.keys(stores).find(key => stores[key] === event.target.value) });
        } else {
            setFilterVal({ ...filterVal, [field]: event.target.value });
        }
    };

    return (
        <div className="row">
            <div className="col-12 col-md-4  col-xl-2 ">
                <Box
                    style={{ marginTop: ".7em" }}
                >
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" className="pt-2">
                            {filterVal.division === 'FFUN Financial' ? 'Company' : 'Store'}
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filterVal.store_name}
                            label="Store"
                            onChange={(e) => handleChange('store_name', e)}
                        >
                            {Object.values(stores).map((child, index) => (
                                <MenuItem value={child} key={index}>{child}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </div>
            <div className="col-12 col-md-2">
                <Box
                    style={{ marginTop: ".7em" }}
                >
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label"  className="pt-2">Fiscal Year</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={filterVal.year}
                            label="Year"
                            onChange={(e) => handleChange('year', e)}
                        >
                            {years.map(child => (
                                <MenuItem value={child}>{child}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </div>
            <div className="col">
                <button className="p-2 mt-3 btn btn-secondary" type="submit"
                    onClick={() => {

                        loadData()
                    }}
                >Show Details</button>
            </div>
        </div>
    )
}

export default DealerYearMonthSelector