import React, { useState, useEffect } from "react";
import './style.css';
import accessData from "../../constants/ceoDashboardAccess";
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import Links from "../../../src/Data/Links.js";
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import PollIcon from '@mui/icons-material/Poll';
import AddIcon from '@mui/icons-material/Add';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PreviewIcon from '@mui/icons-material/Preview';
import { MONTHS, YEARS, WEEKS,MONTH_BY_NUMBER,MONTHSBYNUMBER } from "../../../src/constants.js"
import getFiscals from '../../utils/getFiscals';
import getYearfromFiscal from '../../utils/getYearfromFiscal';
// import Operations from "../../component/CeoDashBoard/Operations";
// import OperationsUsed from "../../component/CeoDashBoard/OperationsUsed"
import OperationsTotal from "../../component/CeoDashBoard/OperationsTotal";
import { calculatePercentageOfBudgetCeoDahboard, calculatePercentageOfBudget } from "../../utils";
import Cookies from 'js-cookie';
import CloseButton from "../../component/CloseButton/index"
import MultiLineChart from "../../component/Charts/MultiLineChart";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CeoDashboardModal from '../ExecutiveReports/CeoDashboardModal.js'
import CeoDashboardNewVehicleModal from '../ExecutiveReports/CeoDashboardNewVehicleModal.js'
import CeoDashboardUsedVehicleModal from '../ExecutiveReports/CeoDashboardUsedVehicleModal.js'
import CeoDashboardApptModal from '../ExecutiveReports/CeoDashboardApptModal.js'
import FfunOneDashboardAccountingModal from '../ExecutiveReports/FfunOneDashboardAccountingModal.js'
import DashboardAccountingModal from '../ExecutiveReports/DashboardAccountingModal.js'


const styles = {
    inputField: {
        width: 120
    },
    tabForm: {
        zIndex: 99,
        maxHeight: "calc(100% - 40px)",
        maxWidth: 600,
    },
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    width: "86%",
    maxWidth: 850,
    minWidth: 320
};

const styleAccounting = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    width: "86%",
    minWidth: 320
};


const Fields = {
    head_count: 0,
    current_posting: 0,
    turnover: 0,
    rev_per_emp: 0,
    gross_per_emp: 0,
    head_count_target: 0,
    current_posting_target: 0,
    turnover_target: 0,
    rev_per_emp_target: 0,
    gross_per_emp_target: 0,
    cash_balance: 0,
    cash_balance_target: 0,
    used_inventory_90_units: 0,
    used_inventory_90_units_target: 0,
    used_inventory_90: 0,
    used_inventory_90_target: 0,
    cash_inventory_45: 0,
    cash_inventory_45_target: 0,
    cash_inventory_all: 0,
    cash_inventory_all_target: 0,
    part_inventory: 0,
    part_inventory_target: 0,
    CIT_10_plus: 0,
    CIT_10_plus_target: 0,
    unique_visitors: 0,
    unique_visitors_target: 0,
    vdp_views: 0,
    vdp_views_target: 0,
    internet_leads: 0,
    internet_leads_target: 0,
    total_leads: 0,
    total_leads_target: 0,
    unique_visitors_ffun: 0,
    unique_visitors_ffun_target: 0,
    vdp_views_ffun: 0,
    vdp_views_ffun_target: 0,
    total_leads_ffun: 0,
    total_leads_ffun_target: 0,
    total_leads_ffun: 0,
    total_leads_ffun_target: 0,
    charge_off_3: 0,
    charge_off_3_target: 0,
    accounts_3: 0,
    accounts_3_target: 0,
    no_of_repos: 0,
    no_of_repos_target: 0,
    deferred_accounts: 0,
    deferred_accounts_target: 0,
    min_monthly_cash: 0,
    min_monthly_cash_target: 0,
    montly_opex: 0,
    montly_opex_target: 0,
    applications: 0,
    applications_target: 0,
    booked_deals: 0,
    booked_deals_target: 0,
    expected_return: 0,
    expected_return_target: 0,
    accounts_per_collector: 0,
    accounts_per_collector_target: 0,
    apps_per_adjudicator: 0,
    apps_per_adjudicator_target: 0,
    ffun_advantage: 0,
    warranties: 0,
    gap: 0,
    creditor: 0,
    ffun_advantage_target: 0,
    warranties_target: 0,
    gap_target: 0,
    creditor_target: 0

}


// const getTimeDifferenceInMinutes = (startTime, endTime) => {
//     if (typeof startTime === 'undefined') {
//         return false
//     }
//     const startDate = moment(startTime).unix()
//     const timeEnd = moment(endTime).unix()
//     const diff = (startDate - timeEnd)
//     if ((60 - (diff / 60 % 60)).toFixed(2) < 3) {
//         return true
//     } else {
//         return false
//     }
// }


const CeoDashboard = () => {
    const userName = JSON.parse(Cookies.get('user_data')).userName;
    const allAccess = accessData[userName?.toLowerCase()];
    const currentYear = moment().format('YYYY');
    const currentMonth = moment().format('MMM');
    const currentDate = moment().format('DD');
    let [currentWeek, setCurrentWeek] = useState(Math.ceil(currentDate / 7) - 1 > 1 ? Math.ceil(currentDate / 7) : 1);
    const [month, setMonth] = useState(currentMonth);
    const [years, setYears] = useState([]);
    const [year, setYear] = useState(currentYear);
    const [week, setWeek] = useState(currentWeek);
    const [dailyDataThisMonthAuto, setDailyDataThisMonthAuto] = useState({});
    const [dailyDataThisMonthRec, setDailyDataThisMonthRec] = useState({});
    const [dailyDataThisMonthUsed, setDailyDataThisMonthUsed] = useState({});
    const [dailyDataThisMonthCreditmaxx, setDailyDataThisMonthCreditmaxx] = useState({});
    const [dailyDataThisMonthOneEarth, setDailyDataThisMonthOneEarth] = useState({});
    const [budgetThisMonthAuto, setBudgetThisMonthAuto] = useState([]);
    const [budgetThisMonthRec, setBudgetThisMonthRec] = useState([]);
    const [budgetThisMonthUsed, setBudgetThisMonthUsed] = useState([]);
    const [budgetThisMonthCreditmaxx, setBudgetThisMonthCreditmaxx] = useState([]);
    const [budgetThisMonthOneEarth, setBudgetThisMonthOneEarth] = useState([]);
    // const [loading, setLoading] = useState(true);
    const [autoEdit, setAutoEdit] = useState(false);
    const [recEdit, setRecEdit] = useState(false);
    const [usedEdit, setUsedEdit] = useState(false);
    const [creditmaxxEdit, setCreditmaxxEdit] = useState(false);
    // const [oneearthEdit, setOneearthEdit] = useState(false);
    let [autoData, setAutoData] = useState(Fields);
    let [recData, setRecData] = useState(Fields);
    let [usedData, setUsedData] = useState(Fields);
    let [creditmaxxData, setCreditmaxxData] = useState(Fields);

    let [autoAccountingData, setAccountingAutoData] = useState(Fields);
    let [recAccountingData, setAccountingRecData] = useState(Fields);
    let [usedAccountingData, setAccountingUsedData] = useState(Fields);
    let [autoAccountingPreviousWeekData, setAccountingPreviousWeekAutoData] = useState(Fields);
    let [recAccountingPreviousWeekData, setAccountingPreviousWeekRecData] = useState(Fields);
    let [usedAccountingPreviousWeekData, setAccountingPreviousWeekUsedData] = useState(Fields);


    let [oneearthData, setOneearthData] = useState(Fields);
    let [autoPreviousWeekData, setPreviousWeekAutoData] = useState(Fields);
    let [recPreviousWeekData, setPreviousWeekRecData] = useState(Fields);
    let [usedPreviousWeekData, setPreviousWeekUsedData] = useState(Fields);
    let [creditmaxxPreviousWeekData, setPreviousWeekCreditmaxxData] = useState(Fields);
    let [oneearthPreviousWeekData, setOneearthPreviousWeekData] = useState(Fields);
    let [buttonFlash, setButtonFlash] = useState(false);
    let [lineModalVisible, setLineModalVisible] = useState(false);
    let [totaGrossModalVisible, setTotaGrossModalVisible] = useState(false);
    let [newVehicleModalVisible, setNewVehicleModalVisible] = useState(false);
    let [usedVehicleModalVisible, setUsedVehicleModalVisible] = useState(false);
    let [apptModalVisible, setApptModalVisible] = useState(false);
    let [divisionForGraph, setDivisionForGraph] = useState("");
    let [graphTitle, setGraphTitle] = useState("");
    let [graphType, setGraphType] = useState("");
    let [labels, setLabels] = useState([]);
    let [targets, setTargets] = useState([]);
    let [actuals, setActuals] = useState([]);
    let [alreadyClicked, setAlreadyCLicked] = useState("");
    let [signInGraph, setSignInGraph] = useState("");
    let [noOfWEEKS, setNoOfWEEKS] = useState(5);
    let [divisionModal,setDivisionModal]=useState("Auto");
    let [typeOfzModal,setTypeOfModal]=useState("soldUnits");
    let [allType,setAllType]=useState("All");
    let [columnNameIs,setColumnNameIs]=useState("");
    let [accountingModal,setAccountingModal]=useState(false);
    let [accountingDivision,setDivisionAccounting]=useState("Auto");
    let [accountingColumnModal,setAccountingColumnModal]=useState(false);
    let [columnName,setColumnName]=useState("");
    let [accountingColumnDivision,setDivisionColumnAccounting]=useState("Auto")


    useEffect(() => {
        GetWeeks(parseInt(MONTH_BY_NUMBER[month]), year);
    }, []);

    const handleSaveAccounting = (event) => {
        setAccountingModal(false);
        
        setTimeout(() => {
            getCurrentCeoAccountingData();
        }, 5000); // 5000 milliseconds = 5 seconds
    };
    const GetWeeks = (m, y) => {
      
        let date = moment().set('year', y).set('month', m - 1).set('date', 1).isoWeekday(8)
        if (date.date() > 7) { // 
            date = date.isoWeekday(-6)
        }
        let newDate = new Date()
        let currentDate = newDate.getDate();;
     
        let theDay = new Date(date._d).getDate()
      
        let days = new Date(y, m, 0).getDate()
      
        let mondays = [theDay];
       
        if ( currentDate < theDay) {
            setMonth(MONTHSBYNUMBER[m-1])
            GetOnlyWeeks(m-1,y,true)
        }
        else{
        
        let count=1;
        for (let i = mondays[0] + 7; i <= days; i += 7) {
            mondays.push(i);
            count++;
        
            // if (currentDate === 0 && currentDate > i ) {  console.log("dayyy",i)
        
                setCurrentWeek(count)
            // }
         
        }
        if ( WEEKS.length !== mondays.length

            ) {
                if(mondays.length> WEEKS.length){
                    WEEKS.push(5);
                }
                else{
                    WEEKS.pop();
                }
                
            }
        setNoOfWEEKS(mondays.length)
        }
    };

    const GetOnlyWeeks = (m, y,isTrue=false) => {
  
        let date = moment().set('year', y).set('month', m - 1).set('date', 1).isoWeekday(8)
        if (date.date() > 7) { // 
            date = date.isoWeekday(-6)
        }
        let newDate = new Date()
        // let currentDate = newDate.getDate();
       
        let theDay = new Date(date._d).getDate()
      
        let days = new Date(y, m, 0).getDate();
     
        let mondays = [theDay];
       
        // if (currentDate === 0 && currentDate < theDay) {
        //     setCurrentWeek(1)
        // }
        let count=1;
        for (let i = mondays[0] + 7; i <= days; i += 7) {
            mondays.push(i);
            count++;
  
            // if (currentDate === 0 && currentDate > i ) {  console.log("dayyy",i)
            // console.log("currentDatedsefsefsd")
            //     setCurrentWeek(count)
            // }
         
        }

        if ( WEEKS.length !== mondays.length

        ) {
            if(mondays.length> WEEKS.length){
                WEEKS.push(5);
            }
            else{
                WEEKS.pop();
            }
            
        }
        setNoOfWEEKS(mondays.length)
        if(isTrue){
            setCurrentWeek(mondays.length)
            setWeek(mondays.length)
        }
        else{
            setCurrentWeek(1)
            setWeek(1)
        }
       
    };
    const handleYearChange = (event) => {
        setYear(event.target.value);
        setButtonFlash(true);
        

        GetOnlyWeeks(parseInt(MONTH_BY_NUMBER[month]),event.target.value)
    };
    const handleMonthChange = (event) => {
        setMonth(event.target.value);
        setButtonFlash(true);
     
        GetOnlyWeeks(parseInt(MONTH_BY_NUMBER[event.target.value]),year)
    };

    const handleWeekChange = (event) => {
        setWeek(event.target.value);
        setButtonFlash(true);
    };


    const getGraphData = (division, type) => {

        const requestBody = {
            year: year,
            month: month,
            week: week,
            division: division
        }

        if (alreadyClicked === "") {
            setAlreadyCLicked(division);
            setGraphType(type);
            if (divisionForGraph !== division || graphType !== type) {
                if (type === "cash_balance" || type === "total_leads" || type === "total_leads_ffun") {
                    axios.post(Links.ceoGraphData, requestBody)
                        .then(result => {

                            let initialLabels = [];
                            let initialActuals = [];
                            let initialTargets = [];
                            let title = "";
                            let sign = "";

                            if (type === "cash_balance") {
                                title = "Cash Balance(" + division?.toUpperCase() + ")";
                                sign = "$"
                            }
                            else if (type === "total_leads") {

                                title = "Total Leads(" + division?.toUpperCase() + ")";
                                sign = ""
                            }
                            else if (type === "total_leads_ffun") {

                                title = "Total Leads(FFUN.COM)";
                                sign = ""
                            }
                            if (result.data.data) {
                                const lastYearData = result.data.data;
                                const length = Object.keys(lastYearData).length;
                                lastYearData.map((data, index) => {
                                    if (type === "cash_balance") {
                                        if (data.cash_balance > 0 && data.cash_balance_target > 0) {
                                            initialLabels.unshift(data.year + "," + data.month + ",week-" + data.week);
                                            initialActuals.unshift(data.cash_balance);
                                            initialTargets.unshift(data.cash_balance_target);

                                        }
                                    }
                                    else if (type === "total_leads") {
                                        if (data.total_leads > 0 && data.total_leads_target > 0) {
                                            initialLabels.unshift(data.year + "," + data.month + ",week-" + data.week);
                                            initialActuals.unshift(data.total_leads);
                                            initialTargets.unshift(data.total_leads_target);

                                        }
                                    }
                                    else if (type === "total_leads_ffun") {
                                        if (data.total_leads_ffun > 0 && data.total_leads_ffun_target > 0) {
                                            initialLabels.unshift(data.year + "," + data.month + ",week-" + data.week);
                                            initialActuals.unshift(data.total_leads_ffun);
                                            initialTargets.unshift(data.total_leads_ffun_target);

                                        }
                                    }



                                    if (index === length - 1) {
                                        setLabels(initialLabels)
                                        setActuals(initialActuals)
                                        setTargets(initialTargets)
                                        setSignInGraph(sign);
                                        setGraphTitle(title);
                                        setLineModalVisible(true)

                                        setDivisionForGraph(division)
                                        setAlreadyCLicked("");
                                    }
                                })


                            }
                            else {
                                setAlreadyCLicked("");
                                alert("sorry, we donn't have enough data to show the comparison graph.")
                            }
                        })
                        .catch(err => {
                            setAlreadyCLicked("");
                            console.log(err);
                        });
                }
                else if (type === "ffun_advantage") {
                    axios.post(Links.ceoGraphFfunAvantageData, requestBody)
                        .then(result => {
                            let initialLabels = [];
                            let initialActuals = [];
                            let initialTargets = [];

                            if (result.data.dailydata) {
                                const GraphdailyData = result.data.dailydata;
                                const GraphBudgetData = result.data.budgetData;
                                let allBudgets = {};
                                const length = Object.keys(GraphBudgetData).length;
                                GraphBudgetData.map((data, index) => {

                                    allBudgets[getYearfromFiscal(data.month, data.YEAR) + "-" + data.month] = data.total_advantage;

                                    if (index === length - 1) {
                                        const lengthDaily = Object.keys(GraphdailyData).length;
                                        GraphdailyData.map((data, index) => {
                                            if (data.total_advantage > 0 && allBudgets[data.YEAR + "-" + data.month] > 0) {
                                                initialLabels.unshift(data.YEAR + "," + data.month);
                                                initialActuals.unshift(data.total_advantage);
                                                initialTargets.unshift(allBudgets[data.YEAR + "-" + data.month]);

                                            }


                                            if (index === lengthDaily - 1) {
                                                setLabels(initialLabels)
                                                setActuals(initialActuals)
                                                setTargets(initialTargets)
                                                setSignInGraph("");
                                                setGraphTitle("OEPP");
                                                setLineModalVisible(true)
                                                setDivisionForGraph(division)
                                                setAlreadyCLicked("");
                                            }
                                        })

                                    }
                                })




                            }
                            else {
                                setAlreadyCLicked("");
                                alert("sorry, we donn't have enough data to show the comparison graph.")
                            }
                        })
                        .catch(err => {
                            setAlreadyCLicked("");
                            console.log(err);
                        });
                }
                else if (type === "new_retail_delivered" || type === "used_retail_delivered") {
                    requestBody.columnName = type;
                    axios.post(Links.ceoGraphOperationsData, requestBody)
                        .then(result => {
                            let initialLabels = [];
                            let initialActuals = [];
                            let initialTargets = [];

                            if (result.data.dailydata) {
                                let title = "";
                                if (type === "new_retail_delivered") {
                                    title = "New Vehicles(" + division?.toUpperCase() + ")"
                                }
                                else if (type === "used_retail_delivered") {
                                    title = "Used Vehicles(" + division?.toUpperCase() + ")"
                                }

                                const GraphdailyData = result.data.dailydata;
                                const GraphBudgetData = result.data.budgetData;
                                let allBudgets = {};
                                const length = Object.keys(GraphBudgetData).length;
                                GraphBudgetData.map((data, index) => {

                                    allBudgets[getYearfromFiscal(data.month, data.YEAR) + "-" + data.month] = data[type] || 0;

                                    if (index === length - 1) {
                                        const lengthDaily = Object.keys(GraphdailyData).length;
                                        GraphdailyData.map((data, index) => {
                                            if (data[type] > 0 && allBudgets[data.YEAR + "-" + data.month] > 0) {
                                                initialLabels.unshift(data.YEAR + "," + data.month);
                                                initialActuals.unshift(data[type]);
                                                initialTargets.unshift(allBudgets[data.YEAR + "-" + data.month]);

                                            }


                                            if (index === lengthDaily - 1) {
                                                setLabels(initialLabels)
                                                setActuals(initialActuals)
                                                setTargets(initialTargets)
                                                setSignInGraph("");
                                                setGraphTitle(title);
                                                setLineModalVisible(true)
                                                setDivisionForGraph(division)
                                                setAlreadyCLicked("");
                                            }
                                        })

                                    }
                                })




                            }
                            else {
                                setAlreadyCLicked("");
                                alert("sorry, we donn't have enough data to show the comparison graph.")
                            }
                        })
                        .catch(err => {
                            setAlreadyCLicked("");
                            console.log(err);
                        });
                }


            }
            else {
                setAlreadyCLicked('');
                setLineModalVisible(true)
            }

        }
    };

    const OnchangeData = (event, onChangeName, division) => {
        const value = event.target.value;
        if (division === "auto") {
            setAutoData(fields => ({ ...fields, [onChangeName]: value }));
        } else if (division === "rec") {
            setRecData(fields => ({ ...fields, [onChangeName]: value }));
        } else if (division === "used") {
            setUsedData(fields => ({ ...fields, [onChangeName]: value }));
        }
        else if (division === "cmaxx") {
            setCreditmaxxData(fields => ({ ...fields, [onChangeName]: value }));
        } else if (division === "oneearth") {
            setOneearthData(fields => ({ ...fields, [onChangeName]: value }));
        }
    }

    const DataFields = ({ name, onChangeName, actualValue = 0, targetValue = 0, isEdit = false, division, sign = "", textColor }) => {
        return (

            <tr>
                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        minWidth: 150,
                        color: textColor ? textColor : "#000"
                    }}
                >
                    {name}

                </td>

                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        color: textColor ? textColor : "#000"
                    }}
                >
                    {
                        sign === "%" ?
                            isEdit === false ? parseInt(actualValue || 0).toString() === "NaN" ? actualValue : parseInt(actualValue).toLocaleString() + sign : <input onBlur={(e) => { OnchangeData(e, onChangeName, division) }} defaultValue={actualValue || 0} style={styles.inputField} />

                            : isEdit === false ? parseInt(actualValue || 0).toString() === "NaN" ? actualValue : sign + parseInt(actualValue || 0).toLocaleString() : <input onBlur={(e) => { OnchangeData(e, onChangeName, division) }} defaultValue={actualValue || 0} style={styles.inputField} />



                    }

                </td>

                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        color: textColor ? textColor : "#000"
                    }}
                >
                    {
                        sign === "%" ?
                            isEdit === false ?
                                parseInt(targetValue || 0).toString() === "NaN" ? targetValue : parseInt(targetValue || 0).toLocaleString() + sign

                                : <input onBlur={(e) => { OnchangeData(e, onChangeName + "_target", division) }} defaultValue={targetValue || 0} style={styles.inputField} />


                            : isEdit === false ? parseInt(targetValue).toString() === "NaN" ? targetValue || 0 : sign + parseInt(targetValue || 0).toLocaleString() : <input onBlur={(e) => { OnchangeData(e, onChangeName + "_target", division) }} defaultValue={targetValue || 0} style={styles.inputField} />


                    }


                </td>
            </tr>

        )
    }

    const DataFieldsAccounting = ({ modalName,name, onChangeName, actualValue = 0, targetValue = 0, previousValue = 0, isEdit = false, division, sign = "", textColor }) => {
        return (

            <tr>
                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        minWidth: 240,
                        color: textColor ? textColor : "#000"
                    }}
                >
                    {name}
                    {modalName!==undefined && modalName!==null && <span title="Click to see the comparison" style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionColumnAccounting(division);
                              setColumnName(modalName);
                              setAccountingColumnModal(true);
                              }} /></span>}
               </td>

                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        color: textColor ? textColor : "#000"
                    }}

                >

                     {sign !== "%" ? parseInt(actualValue).toLocaleString() === "NaN" ? actualValue || 0 : sign + parseInt(actualValue || 0).toLocaleString() : parseInt(actualValue || 0).toLocaleString() === "NaN" ? actualValue || 0 : parseInt(actualValue || 0).toLocaleString() + sign }
                    
                </td>

                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        color: textColor ? textColor : "#000"
                    }}
                >
                   
                {    sign !== "%" ? parseInt(targetValue).toString() === "NaN" ? targetValue || 0 : sign + parseInt(targetValue || 0).toLocaleString() : parseInt(targetValue || 0).toString() === "NaN" ? targetValue || 0 : parseInt(targetValue || 0).toLocaleString() + sign }
            

                </td>

                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        minWidth: 100,
                        color: '#084298'
                    }}
                >
                    {sign !== "%" ? parseInt(previousValue).toString() === "NaN" ? previousValue || 0 : sign + parseInt(previousValue || 0).toLocaleString() : parseInt(previousValue || 0).toString() === "NaN" ? previousValue || 0 : parseInt(previousValue || 0).toLocaleString() + sign}





                </td>


            </tr>

        )
    }

    const DataFieldsAccountingCreditMaxx = ({ name, onChangeName, actualValue = 0, targetValue = 0, previousValue = 0, isEdit = false, division, sign = "", textColor }) => {
        return (

            <tr>
                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        minWidth: 240,
                        color: textColor ? textColor : "#000"
                    }}
                >
                    {name}

                </td>

                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        color: textColor ? textColor : "#000"
                    }}

                >

                    {isEdit === false ?

                        sign !== "%" ? parseInt(actualValue).toString() === "NaN" ? actualValue || 0 : sign + (parseFloat(actualValue || 0).toFixed(2)).toLocaleString() : parseInt(actualValue).toString() === "NaN" ? actualValue || 0 : (parseFloat(actualValue || 0).toFixed(2)).toLocaleString() + sign

                        : <input onBlur={(e) => { OnchangeData(e, onChangeName, division) }} defaultValue={actualValue || 0} style={styles.inputField} />}

                </td>

                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        color: textColor ? textColor : "#000"
                    }}
                >
                    {isEdit === false ? sign !== "%" ?
                        parseInt(targetValue).toString() === "NaN" ? targetValue || 0 : sign + (parseFloat(targetValue || 0).toFixed(2)).toLocaleString() : parseInt(targetValue).toString() === "NaN" ? targetValue || 0 : (parseFloat(targetValue || 0).toFixed(2)).toLocaleString() + sign

                        : <input onBlur={(e) => { OnchangeData(e, onChangeName + "_target", division) }} defaultValue={targetValue || 0} style={styles.inputField} />}


                </td>

                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        minWidth: 100,
                        color: textColor ? textColor : "#000"
                    }}
                >
                    {sign !== "%" ?


                        parseInt(previousValue).toString() === "NaN" ? previousValue || 0 : sign + (parseFloat(previousValue || 0).toFixed(2)).toLocaleString() : parseInt(previousValue).toString() === "NaN" ? previousValue || 0 : (parseFloat(previousValue || 0).toFixed(2)).toLocaleString() + sign
                    }





                </td>


            </tr>

        )
    }

    const DataFieldsMarketing = ({ name, onChangeName, actualValue = 0, targetValue = 0, isEdit = false, division, sign = "", prevText = "" }) => {
        let graphName = name === "OEPP" ? "ffun_advantage" : "total_leads";
        if (onChangeName === "total_leads_ffun") {
            graphName = onChangeName;
        }
        return (

            <tr>
                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                        minWidth: 150
                    }}
                >
                    {name}
                    {(name === "OEPP" || name === "Total Leads") && division !== "all" && <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><PollIcon style={{ color: "orange" }} onClick={() => getGraphData(division, graphName)} /></span>}

                </td>

                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                    }}
                >
                    {isEdit === false ? sign !== "%" ? parseInt(actualValue).toString() === "NaN" ? actualValue || 0 : prevText + sign + (actualValue || 0).toLocaleString() : parseInt(actualValue).toString() === "NaN" ? actualValue || 0 : prevText + (actualValue || 0).toLocaleString() + sign : <input onBlur={(e) => { OnchangeData(e, onChangeName, division) }} defaultValue={actualValue || 0} style={styles.inputField} />}

                </td>

                <td
                    style={{
                        padding: "10px",
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                    }}
                >
                    {isEdit === false ? sign !== "%" ?

                        parseInt(targetValue).toString() === "NaN" ? targetValue || 0 : prevText + sign + parseInt(targetValue || 0).toLocaleString() : parseInt(targetValue).toString() === "NaN" ? targetValue || 0 : prevText + parseInt(targetValue || 0).toLocaleString() + sign : <input onBlur={(e) => { OnchangeData(e, onChangeName + "_target", division) }} defaultValue={targetValue || 0} style={styles.inputField} />}


                </td>

                <td
                    style={{
                        fontWeight: "bold",
                        fontSize: "1rem",
                        position: "relative",
                        textAlign: "left",
                        minWidth: 150
                    }}
                >
                    <div
                        className="progress"
                        style={{
                            height: "100%",
                            width: "100%",
                            position: "absolute",
                            right: "0%",
                            top: "0%",
                            zIndex: "-1",
                            borderRadius: "0px",
                        }}
                    >
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                                backgroundColor: "#7CEF7C ",
                                width:
                                    calculatePercentageOfBudgetCeoDahboard(
                                        actualValue || 0,
                                        targetValue || 0)

                            }}
                            aria-valuenow="25"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>
                    {
                        calculatePercentageOfBudgetCeoDahboard(
                            actualValue || 0,
                            targetValue || 0)}
                </td>


            </tr>

        )
    }

    const AddData = (division) => {

        const requestBody = {
            year: year,
            month: month,
            week: week,
            division: division,
            locked_user: userName
        }
        let requestData;
        if (division === "auto") {
            setAutoEdit(false);
            requestData = { ...autoData, ...requestBody }
        } else if (division === "rec") {
            setRecEdit(false);
            requestData = { ...recData, ...requestBody }
        } else if (division === "used") {
            setUsedEdit(false);
            requestData = { ...usedData, ...requestBody }
        }
        else if (division === "cmaxx") {
            setCreditmaxxEdit(false);
            requestData = { ...creditmaxxData, ...requestBody }
        }
        // else if (division === "oneearth") {
        //     setOneearthEdit(false);
        //     requestData = { ...oneearthData, ...requestBody }
        // }


        if (requestData.id > 0) {
            axios.post(Links.updateCeoDashboardData, requestData)
                .then(result => {

                    if (result.data.message === "updated!") {
                        alert("The data added/updated successfully!")
                    }
                    else {
                        alert(result.data.message)
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }
        else {
            alert("Something went wrong! Please reload and try again.")
            // axios.post(Links.addCeoDashboardData, requestData)
            //     .then(result => {

            //     })
            //     .catch(err => {
            //         console.log(err);
            //     });
        }


    }
    function Operations({ dailyData, budgetData, division }) {
        const totalNewFrontGP = dailyData?.total_new_front_GP ? parseInt(dailyData.total_new_front_GP) : 0;
        const totalNewBackGP = dailyData.total_new_back_GP ? parseInt(dailyData.total_new_back_GP) : 0;
        const totalUsedFrontGP = dailyData.total_used_front_GP ? parseInt(dailyData.total_used_front_GP) : 0;
        const totalUsedBackGP = dailyData.total_used_back_GP ? parseInt(dailyData.total_used_back_GP) : 0;
        const totalServiceGP = dailyData.total_service_GP ? parseInt(dailyData.total_service_GP) : 0;
        const totalApparelGross = dailyData.total_apparel_gross ? parseInt(dailyData.total_apparel_gross) : 0;
        const totalBoatsFrontGP = dailyData.total_boats_front_GP ? parseInt(dailyData.total_boats_front_GP) : 0;
        const totalBoatsBackGP = dailyData.total_boats_back_GP ? parseInt(dailyData.total_boats_back_GP) : 0;
        const totalTrailersFrontGP = dailyData.total_trailers_front_GP ? parseInt(dailyData.total_trailers_front_GP) : 0;
        const totalTrailersBackGP = dailyData.total_trailers_back_GP ? parseInt(dailyData.total_trailers_back_GP) : 0;
        const totalPartsGP = dailyData.total_parts_retail_GP ? parseInt(dailyData.total_parts_retail_GP) : 0;

        const totalDailyData = totalNewFrontGP + totalNewBackGP + totalUsedFrontGP + totalUsedBackGP + totalServiceGP + totalApparelGross + totalBoatsFrontGP + totalBoatsBackGP + totalTrailersFrontGP + totalTrailersBackGP + totalPartsGP;



        const totalNewFrontGPBudget = budgetData?.total_new_front_GP ? parseInt(budgetData.total_new_front_GP) : 0;
        const totalNewBackGPBudget = budgetData.total_new_back_GP ? parseInt(budgetData.total_new_back_GP) : 0;
        const totalUsedFrontGPBudget = budgetData.total_used_front_GP ? parseInt(budgetData.total_used_front_GP) : 0;
        const totalUsedBackGPBudget = budgetData.total_used_back_GP ? parseInt(budgetData.total_used_back_GP) : 0;
        const totalNewDelivered = dailyData?.total_new_delivered ? parseInt(dailyData.total_new_delivered) : 0;
        const totalUsedDelivered = dailyData?.total_used_delivered ? parseInt(dailyData.total_used_delivered) : 0;
        const totalNewDeliveredBudget = budgetData?.total_new_delivered ? parseInt(budgetData.total_new_delivered) : 0;
        const totalUsedDeliveredBudget = budgetData?.total_used_delivered ? parseInt(budgetData.total_used_delivered) : 0;
        const totalBoatsFrontGPBudget = budgetData.total_boats_front_GP ? parseInt(budgetData.total_boats_front_GP) : 0;
        const totalBoatsBackGPBudget = budgetData.total_boats_back_GP ? parseInt(budgetData.total_boats_back_GP) : 0;
        const totalTrailersFrontGPBudget = budgetData.total_trailers_front_GP ? parseInt(budgetData.total_trailers_front_GP) : 0;
        const totalTrailersBackGPBudget = budgetData.total_trailers_back_GP ? parseInt(budgetData.total_trailers_back_GP) : 0;
        const totalServiceGPBudget = budgetData.total_service_GP ? parseInt(budgetData.total_service_GP) : 0;
        const totalApparelGrossBudget = budgetData.total_apparel_gross ? parseInt(budgetData.total_apparel_gross) : 0;
        const totalPartsGPBudget = budgetData.total_parts_retail_GP ? parseInt(budgetData.total_parts_retail_GP) : 0;

        const totalBudgetData = totalNewFrontGPBudget + totalNewBackGPBudget + totalUsedFrontGPBudget + totalUsedBackGPBudget + totalServiceGPBudget + totalApparelGrossBudget + totalBoatsFrontGPBudget + totalBoatsBackGPBudget + totalTrailersFrontGPBudget + totalTrailersBackGPBudget + totalPartsGPBudget;



        return (
            <table
                className="table table-bordered border-black table-fit"
                style={{ marginBottom: 0 }}
            >
                <thead>
                    <tr className="text-center">
                        <th
                            colSpan="1"
                            className="ceo-header"
                            style={{ backgroundColor: "#a2c1a2", fontSize: "1rem", width: 150 }}
                        >
                            Category
                        </th>
                        <th
                            colSpan="1"
                            className="ceo-header"
                            style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                        >
                            Actual
                        </th>
                        <th
                            colSpan="1"
                            className="ceo-header"
                            style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                        >
                            Target
                        </th>
                        <th
                            colSpan="1"
                            className="ceo-header"
                            style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                        >
                            Monthly Trend
                        </th>


                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                minWidth: "150px"
                            }}
                        >
                            New Vehicles
                        {/* style={{ color: "orange" }} onClick={() => getGraphData(division, "new_retail_delivered")} /></span> */}
                        <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal(division=="auto"?"Auto":"Rec");
                              setNewVehicleModalVisible(true);
                              setTypeOfModal("soldUnits");
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {dailyData?.total_new_delivered || 0}

                        </td>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {budgetData?.total_new_delivered || 0}

                        </td>

                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_new_delivered || 0,
                                                budgetData?.total_new_delivered || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_new_delivered || 0,
                                    budgetData?.total_new_delivered || 0)}
                        </td>
                    </tr>

                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Used Vehicles
                            {/* <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><PollIcon style={{ color: "orange" }} onClick={() => getGraphData(division, "used_retail_delivered")} /></span> */}
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal(division=="auto"?"Auto":"Rec");
                              setUsedVehicleModalVisible(true);
                              setTypeOfModal("soldUnits");
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(dailyData?.total_used_delivered || 0).toLocaleString()}

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(budgetData?.total_used_delivered || 0).toLocaleString()}

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_used_delivered || 0,
                                                budgetData?.total_used_delivered || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_used_delivered || 0,
                                    budgetData?.total_used_delivered || 0)}
                        </td>
                    </tr>
                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >

                            New Vehicles - Total Gross
  {/* <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal(division=="auto"?"Auto":"Rec");
                              setNewVehicleModalVisible(true);
                              setTypeOfModal("gross");
                              }} /></span> */}
                              <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal(division=="auto"?"Auto":"Rec");
                              setTotaGrossModalVisible(true);
                              setAllType("New Vehicles")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(totalNewFrontGP + totalNewBackGP).toLocaleString()}

                        </td>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(totalNewFrontGPBudget + totalNewBackGPBudget).toLocaleString()}

                        </td>

                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                (totalNewFrontGP + totalNewBackGP),
                                                (totalNewFrontGPBudget + totalNewBackGPBudget))

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    (totalNewFrontGP + totalNewBackGP),
                                    (totalNewFrontGPBudget + totalNewBackGPBudget))}
                        </td>
                    </tr>
                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Used Vehicles - Total Gross
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal(division=="auto"?"Auto":"Rec");
                              setTotaGrossModalVisible(true);
                              setAllType("Used Vehicles")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(totalUsedFrontGP + totalUsedBackGP).toLocaleString()}

                        </td>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(totalUsedFrontGPBudget + totalUsedBackGPBudget).toLocaleString()}

                        </td>


                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                (totalUsedFrontGP + totalUsedBackGP),
                                                (totalUsedFrontGPBudget + totalUsedBackGPBudget))

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    (totalUsedFrontGP + totalUsedBackGP),
                                    (totalUsedFrontGPBudget + totalUsedBackGPBudget))}
                        </td>


                    </tr>
                    {dailyData.total_boats_front_GP !== undefined && <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Boats - Total Gross
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal("Rec");
                              setTotaGrossModalVisible(true);
                              setAllType("Boats")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(totalBoatsFrontGP + totalBoatsBackGP).toLocaleString()}

                        </td>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(totalBoatsFrontGPBudget + totalBoatsBackGPBudget).toLocaleString()}

                        </td>


                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                totalBoatsFrontGP + totalBoatsBackGP,
                                                totalBoatsFrontGPBudget + totalBoatsBackGPBudget)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    totalBoatsFrontGP + totalBoatsBackGP,
                                    totalBoatsFrontGPBudget + totalBoatsBackGPBudget)}
                        </td>


                    </tr>
                    }

                    {dailyData.total_boats_front_GP !== undefined && <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Trailers - Total Gross
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal("Rec");
                              setTotaGrossModalVisible(true);
                              setAllType("Trailers")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(totalTrailersFrontGP + totalTrailersBackGP).toLocaleString()}

                        </td>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(totalTrailersFrontGPBudget + totalTrailersBackGPBudget).toLocaleString()}

                        </td>


                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                totalTrailersFrontGP + totalTrailersBackGP,
                                                totalTrailersFrontGPBudget + totalTrailersBackGPBudget)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    totalTrailersFrontGP + totalTrailersBackGP,
                                    totalTrailersFrontGPBudget + totalTrailersBackGPBudget)}
                        </td>


                    </tr>
                    }

                    {dailyData.total_apparel_gross !== undefined && <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Apparel - Total Gross
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal("Rec");
                              setTotaGrossModalVisible(true);
                              setAllType("Apparel")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(dailyData?.total_apparel_gross || 0).toLocaleString()}

                        </td>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(budgetData?.total_apparel_gross || 0).toLocaleString()}

                        </td>


                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_apparel_gross || 0,
                                                budgetData?.total_apparel_gross || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_apparel_gross || 0,
                                    budgetData?.total_apparel_gross || 0)}
                        </td>


                    </tr>
                    }





                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Parts - Total Gross
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal(division=="auto"?"Auto":"Rec");
                              setTotaGrossModalVisible(true);
                              setAllType("Parts")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(dailyData?.total_parts_retail_GP || 0).toLocaleString()}

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(budgetData?.total_parts_retail_GP || 0).toLocaleString()}

                        </td>

                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_parts_retail_GP || 0,
                                                budgetData?.total_parts_retail_GP || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_parts_retail_GP || 0,
                                    budgetData?.total_parts_retail_GP || 0)}
                        </td>




                    </tr>
                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Services - Total Gross
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal(division=="auto"?"Auto":"Rec");
                              setTotaGrossModalVisible(true);
                              setAllType("Services")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(dailyData?.total_service_GP || 0).toLocaleString()}

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(budgetData?.total_service_GP || 0).toLocaleString()}

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_service_GP || 0,
                                                budgetData?.total_service_GP || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_service_GP || 0,
                                    budgetData?.total_service_GP || 0)}
                        </td>
                    </tr>


                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#a2c1a2"
                            }}
                        >
                            Total - Total Gross
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                                setDivisionModal(division=="auto"?"Auto":"Rec");
                                setTotaGrossModalVisible(true);
                                setAllType("All");
                                }} /></span>

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#a2c1a2"
                            }}
                        >
                            ${(totalDailyData).toLocaleString()}

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#a2c1a2"
                            }}
                        >
                            ${(totalBudgetData).toLocaleString()}

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                totalDailyData,
                                                totalBudgetData)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    totalDailyData,
                                    totalBudgetData)}
                        </td>
                    </tr>


                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Scheduled Appts shown
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal(division=="auto"?"Auto":"Rec");
                              setApptModalVisible(true);
                              setColumnNameIs("SAS")
                              }} /></span>

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(dailyData?.total_appt_show || 0).toLocaleString()}

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(budgetData?.total_appt_show || 0).toLocaleString()}

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_appt_show || 0,
                                                budgetData?.total_appt_show || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_appt_show || 0,
                                    budgetData?.total_appt_show || 0)}
                        </td>
                    </tr>

                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Appointments
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal(division=="auto"?"Auto":"Rec");
                              setApptModalVisible(true);
                              setColumnNameIs("APPT")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(dailyData?.total_appt_made || 0).toLocaleString()}

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(budgetData?.total_appt_made || 0).toLocaleString()}

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_appt_made || 0,
                                                budgetData?.total_appt_made || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_appt_made || 0,
                                    budgetData?.total_appt_made || 0)}
                        </td>
                    </tr>


                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Store Visit
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal(division=="auto"?"Auto":"Rec");
                              setApptModalVisible(true);
                              setColumnNameIs("SV")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(dailyData?.total_store_visit || 0).toLocaleString()}

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(budgetData?.total_store_visit || 0).toLocaleString()}

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_store_visit || 0,
                                                budgetData?.total_store_visit || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_store_visit || 0,
                                    budgetData?.total_store_visit || 0)}
                        </td>
                    </tr>

                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Closing
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal(division=="auto"?"Auto":"Rec");
                              setApptModalVisible(true);
                              setColumnNameIs("CLS")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {isFinite((totalNewDelivered + totalUsedDelivered) / dailyData?.total_store_visit || 0) ? ((totalNewDelivered + totalUsedDelivered) / dailyData?.total_store_visit).toFixed(2) : 0}%


                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >

                            {isFinite((totalNewDeliveredBudget + totalUsedDeliveredBudget) / budgetData?.total_store_visit || 0) ? ((totalNewDeliveredBudget + totalUsedDeliveredBudget) / budgetData?.total_store_visit || 0).toFixed(2) : 0}%

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_store_visit || 0,
                                                budgetData?.total_store_visit || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_store_visit || 0,
                                    budgetData?.total_store_visit || 0)}
                        </td>
                    </tr>


                </tbody>
            </table>
        )
    }

    const OperationsUsed = ({ dailyData, budgetData, division }) => {


        const totalUsedFrontGP = dailyData.total_used_front_GP ? parseInt(dailyData.total_used_front_GP) : 0;
        const totalUsedBackGP = dailyData.total_used_back_GP ? parseInt(dailyData.total_used_back_GP) : 0;
        const totalServiceGP = dailyData?.total_service_GP ? parseInt(dailyData?.total_service_GP) : 0;
        const totalDailyData = totalUsedFrontGP + totalUsedBackGP + totalServiceGP;

        const totalUsedFrontGPBudget = budgetData.total_used_front_GP ? parseInt(budgetData.total_used_front_GP) : 0;
        const totalUsedBackGPBudget = budgetData.total_used_back_GP ? parseInt(budgetData.total_used_back_GP) : 0;
        const totalServiceGPBudget = budgetData?.total_service_GP ? parseInt(budgetData?.total_service_GP) : 0;

        const totalBudgetData = totalUsedFrontGPBudget + totalUsedBackGPBudget + totalServiceGPBudget;

        const totalUsedDelivered = dailyData?.total_used_delivered ? parseInt(dailyData.total_used_delivered) : 0;

        const totalUsedDeliveredBudget = budgetData?.total_used_delivered ? parseInt(budgetData.total_used_delivered) : 0;


        return (
            <table
                className="table table-bordered border-black table-fit"
                style={{ marginBottom: 0 }}
            >
                <thead>
                    <tr className="text-center">
                        <th
                            colSpan="1"
                            className="ceo-header"
                            style={{ backgroundColor: "#a2c1a2", fontSize: "1rem", width: 150 }}
                        >
                            Category
                        </th>
                        <th
                            colSpan="1"
                            className="ceo-header"
                            style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                        >
                            Actual
                        </th>
                        <th
                            colSpan="1"
                            className="ceo-header"
                            style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                        >
                            Target
                        </th>
                        <th
                            colSpan="1"
                            className="ceo-header"
                            style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                        >
                            Monthly Trend
                        </th>


                    </tr>
                </thead>
                <tbody>


                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Used Vehicles
                            {/* <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><PollIcon style={{ color: "orange" }} onClick={() => getGraphData(division, "used_retail_delivered")} /></span> */}
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal("Used");
                              setUsedVehicleModalVisible(true);
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(dailyData?.total_used_delivered || 0).toLocaleString()}

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(budgetData?.total_used_delivered || 0).toLocaleString()}

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_used_delivered || 0,
                                                budgetData?.total_used_delivered || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_used_delivered || 0,
                                    budgetData?.total_used_delivered || 0)}
                        </td>
                    </tr>

                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Used Vehicles - Total Gross
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                               
                               setDivisionModal("Used");
                               setTotaGrossModalVisible(true);
                               setAllType("Used Vehicles");
                               }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(totalUsedFrontGP + totalUsedBackGP).toLocaleString()}

                        </td>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(totalUsedFrontGPBudget + totalUsedBackGPBudget).toLocaleString()}

                        </td>


                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                (totalUsedFrontGP + totalUsedBackGP),
                                                (totalUsedFrontGPBudget + totalUsedBackGPBudget))

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    (totalUsedFrontGP + totalUsedBackGP),
                                    (totalUsedFrontGPBudget + totalUsedBackGPBudget))}
                        </td>


                    </tr>

                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Services - Total Gross
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                               
                               setDivisionModal("Used");
                               setTotaGrossModalVisible(true);
                               setAllType("Services");
                               }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(dailyData?.total_service_GP || 0).toLocaleString()}

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            ${(budgetData?.total_service_GP || 0).toLocaleString()}

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_service_GP || 0,
                                                budgetData?.total_service_GP || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_service_GP || 0,
                                    budgetData?.total_service_GP || 0)}
                        </td>
                    </tr>

                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#a2c1a2"
                            }}
                        >
                            Total - Total Gross
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                               
                                setDivisionModal("Used");
                                setTotaGrossModalVisible(true);
                                setAllType("All");
                                }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#a2c1a2"
                            }}
                        >
                            ${(totalDailyData).toLocaleString()}

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                color: "#a2c1a2"
                            }}
                        >
                            ${(totalBudgetData).toLocaleString()}

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_service_GP || 0,
                                                budgetData?.total_service_GP || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_service_GP || 0,
                                    budgetData?.total_service_GP || 0)}
                        </td>
                    </tr>


                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Scheduled Appts shown
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal("Used");
                              setApptModalVisible(true);
                              setColumnNameIs("SAS")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(dailyData?.total_appt_show || 0).toLocaleString()}

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(budgetData?.total_appt_show || 0).toLocaleString()}

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_appt_show || 0,
                                                budgetData?.total_appt_show || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_appt_show || 0,
                                    budgetData?.total_appt_show || 0)}
                        </td>
                    </tr>

                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Appointments
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal("Used");
                              setApptModalVisible(true);
                              setColumnNameIs("APPT")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(dailyData?.total_appt_made || 0).toLocaleString()}

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(budgetData?.total_appt_made || 0).toLocaleString()}

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_appt_made || 0,
                                                budgetData?.total_appt_made || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_appt_made || 0,
                                    budgetData?.total_appt_made || 0)}
                        </td>
                    </tr>


                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Store Visit
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal("Used");
                              setApptModalVisible(true);
                              setColumnNameIs("SV")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(dailyData?.total_store_visit || 0).toLocaleString()}

                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {(budgetData?.total_store_visit || 0).toLocaleString()}

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_store_visit || 0,
                                                budgetData?.total_store_visit || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_store_visit || 0,
                                    budgetData?.total_store_visit || 0)}
                        </td>
                    </tr>

                    <tr>
                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            Closing
                            <span title="Click to see the comparison graph." style={{ cursor: "pointer" }} className="ml-2"><AddBoxIcon style={{ color: "orange" }} onClick={() => {
                              
                              setDivisionModal("Used");
                              setApptModalVisible(true);
                              setColumnNameIs("CLS")
                              }} /></span>
                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >

                            {isFinite((totalUsedDelivered) / dailyData?.total_store_visit || 0) ? (((totalUsedDelivered) / dailyData?.total_store_visit || 0).toFixed(2)) : 0}%



                        </td>

                        <td
                            style={{
                                paddingTop: "10px",
                                textAlign: "left",
                                fontSize: "1rem",
                                fontWeight: "bold",
                            }}
                        >
                            {isFinite((totalUsedDeliveredBudget) / budgetData?.total_store_visit || 0) ? (((totalUsedDeliveredBudget) / budgetData?.total_store_visit || 0).toFixed(2)) : 0}%

                        </td>
                        <td
                            style={{
                                fontWeight: "bold",
                                fontSize: "1rem",
                                position: "relative",
                                textAlign: "left",
                                minWidth: 150
                            }}
                        >
                            <div
                                className="progress"
                                style={{
                                    height: "100%",
                                    width: "100%",
                                    position: "absolute",
                                    right: "0%",
                                    top: "0%",
                                    zIndex: "-1",
                                    borderRadius: "0px",
                                }}
                            >
                                <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                        backgroundColor: "#7CEF7C ",
                                        width:
                                            calculatePercentageOfBudget(
                                                dailyData?.total_store_visit || 0,
                                                budgetData?.total_store_visit || 0)

                                    }}
                                    aria-valuenow="25"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                ></div>
                            </div>
                            {
                                calculatePercentageOfBudget(
                                    dailyData?.total_store_visit || 0,
                                    budgetData?.total_store_visit || 0)}
                        </td>
                    </tr>


                </tbody>
            </table>
        )
    }

    const CheckIfEditAvailable = (division, type) => {

        const requestBody = {
            year: year,
            month: month,
            week: week,
            division: division,
            locked_user: userName,
            type: type
        }

        let requestData;
        if (division === "auto") {
            // setAutoEdit(false);
            requestData = { ...autoData, ...requestBody }
        } else if (division === "rec") {
            // setRecEdit(false);
            requestData = { ...recData, ...requestBody }
        } else if (division === "used") {
            // setUsedEdit(false);
            requestData = { ...usedData, ...requestBody }
        }
        else if (division === "cmaxx") {
            // setUsedEdit(false);
            requestData = { ...creditmaxxData, ...requestBody }
        }
        else if (division === "oneearth") {
            // setUsedEdit(false);
            requestData = { ...oneearthData, ...requestBody }
        }
        // if (type === "add") {

        // }
        // else {
        axios.post(Links.ceoCheckIfLocked, requestData)
            .then(result => {

                if (division === "auto") {
                    if (result.data.message === "start!") {
                        setAutoEdit(true);
                        setAutoData(result.data.data[0][0])
                    }
                    else {
                        alert(result.data.message);
                        setAutoData(result.data.data[0][0])
                    }

                } else if (division === "rec") {
                    if (result.data.message === "start!") {
                        setRecData(result.data.data[0][0])
                        setRecEdit(true);

                    } else {
                        alert(result.data.message);
                        setRecData(result.data.data[0][0])
                    }

                } else if (division === "used") {
                    if (result.data.message === "start!") {
                        setUsedData(result.data.data[0][0])
                        setUsedEdit(true);
                    } else {
                        alert(result.data.message);
                        setUsedData(result.data.data[0][0])
                    }

                }
                else if (division === "cmaxx") {
                    if (result.data.message === "start!") {
                        setCreditmaxxData(result.data.data[0][0])
                        setCreditmaxxEdit(true);
                    } else {
                        alert(result.data.message);
                        setCreditmaxxData(result.data.data[0][0])
                    }

                }
                else if (division === "oneearth") {
                    if (result.data.message === "start!") {
                        setOneearthData(result.data.data[0][0])
                        // setOneearthEdit(true);
                    } else {
                        alert(result.data.message);
                        setOneearthData(result.data.data[0][0])
                    }

                }
            })
            .catch(err => {
                console.log(err);
            });
        // }


    }
    const options = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: true,
                text: 'Actual vs Target',
            },
            datalabels: {
                display: false
            }
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    beginAtZero: true,
                    callback: function (value) {
                        if (signInGraph === "$") {
                            return `$${value?.toLocaleString()}`;
                        }
                        else {
                            return `${value?.toLocaleString()}`;
                        }

                    }
                }

            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
                ticks: {
                    display: false,
                },
            },
        },
    };
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Actual',
                backgroundColor: '#ffa500',
                borderColor: '#ffa500',
                borderWidth: 2,
                hoverBackgroundColor: '#ffa500',
                hoverBorderColor: '#ffa500',
                data: actuals,
                yAxisID: 'y',
            },
            {
                label: 'Target',
                backgroundColor: '#00FF00',
                borderColor: '#00FF00',
                borderWidth: 1,
                hoverBackgroundColor: '#00FF00',
                hoverBorderColor: '#00FF00',
                data: targets,
                yAxisID: 'y',
            }
        ],

        // scales: {
        //     y: {
        //       type: 'linear',
        //       display: true,
        //       position: 'left',
        //     },
        //     y1: {
        //       type: 'linear',
        //       display: true,
        //       position: 'right',

        //       // grid line settings
        //       grid: {
        //         drawOnChartArea: false, // only want the grid lines for one axis to show up
        //       }
        //     }
        // }
    };

    const MultiLineChartModal = () => {

        return (
            <Modal
                open={lineModalVisible}
                onClose={() => { setLineModalVisible(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <MultiLineChart title={graphTitle} data={chartData} options={options} />
                </Box>
            </Modal>

        );
    };
    const AccountingModal = () => {

        return (
            <Modal
                open={accountingModal}
                onClose={() => { setAccountingModal(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleAccounting}>
                    <FfunOneDashboardAccountingModal division={accountingDivision}   Modalmonth={month} Modalyear={year} week={week} handleSaveAccounting={handleSaveAccounting}/>
                </Box>
            </Modal>

        );
    };

    const AccountingColumnModal = () => {

        return (
            <Modal
                open={accountingColumnModal}
                onClose={() => { setAccountingColumnModal(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleAccounting}>
                    <DashboardAccountingModal division={accountingColumnDivision}   Modalmonth={month} Modalyear={year} week={week} columnName={columnName}/>
                </Box>
            </Modal>

        );
    };

    const DivisionalTotalGrossModal = () => {

        return (

            <Modal
                open={totaGrossModalVisible}
                onClose={() => { setTotaGrossModalVisible(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CeoDashboardModal division={divisionModal}  Modalmonth={month} Modalyear={year} allType={allType}/>
                </Box>
            </Modal>

        );
    };

    const DivisionalNewVehicleModal = () => {

        return (

            <Modal
                open={newVehicleModalVisible}
                onClose={() => { setNewVehicleModalVisible(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CeoDashboardNewVehicleModal division={divisionModal}  Modalmonth={month} Modalyear={year}/>
                </Box>
            </Modal>

        );
    };

    const DivisionalUsedVehicleModal = () => {

        return (

            <Modal
                open={usedVehicleModalVisible}
                onClose={() => { setUsedVehicleModalVisible(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CeoDashboardUsedVehicleModal division={divisionModal}  Modalmonth={month} Modalyear={year}/>
                </Box>
            </Modal>

        );
    };


    const DivisionalApptModal = () => {

        return (

            <Modal
                open={apptModalVisible}
                onClose={() => { setApptModalVisible(false) }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CeoDashboardApptModal division={divisionModal} columnName={columnNameIs}  Modalmonth={month} Modalyear={year}/>
                </Box>
            </Modal>

        );
    };


    useEffect(() => {
        setYears(YEARS);
        getCurrentCeoData();
        getAutoData(year, month);
        getUsedData(year, month);
        getRecData(year, month);
        getCreditMaxxData(year, month);
        getOneEarthData(year, month);
        getCurrentCeoAccountingData();

    }, []);


    const showResults = () => {
        setAutoEdit(false);
        setRecEdit(false);
        setUsedEdit(false);
        getCurrentCeoData();
        getAutoData(year, month);
        getUsedData(year, month);
        getRecData(year, month);
        getCreditMaxxData(year, month);
        getOneEarthData(year, month);
        getCurrentCeoAccountingData();
    };

    const getCurrentCeoAccountingData = () => {

        const requestBody = {
            year: year,
            month: month,
            week: week
        }
        axios.post(Links.ceoAccountingGetWeeklyData, requestBody)
            .then(result => {
                if (result.data.autoData[0] !== undefined) {

                    setAccountingAutoData(result.data.autoData[0])
                } else {
                    setAccountingAutoData(Fields)
                }

                if (result.data.previousWeekDataAuto[0] !== undefined) {
                    setAccountingPreviousWeekAutoData(result.data.previousWeekDataAuto[0])
                } else {
                    setAccountingPreviousWeekAutoData(Fields)
                }

                if (result.data.recData[0] !== undefined) {
                    setAccountingRecData(result.data.recData[0])
                } else {
                    setAccountingRecData(Fields)
                }

                if (result.data.previousWeekDataRec[0] !== undefined) {
                    setAccountingPreviousWeekRecData(result.data.previousWeekDataRec[0])
                } else {
                    setAccountingPreviousWeekRecData(Fields)
                }

                if (result.data.usedData[0] !== undefined) {
                    setAccountingUsedData(result.data.usedData[0])
                } else {
                    setAccountingUsedData(Fields)
                }

                if (result.data.previousWeekDataUsed[0] !== undefined) {
                    setAccountingPreviousWeekUsedData(result.data.previousWeekDataUsed[0])
                } else {
                    setAccountingPreviousWeekUsedData(Fields)
                }


            })
            .catch(err => {
                console.log(err);
            });


    };
    const getCurrentCeoData = () => {

        const requestBody = {
            year: year,
            month: month,
            week: week
        }
        axios.post(Links.getCeoDashboardData, requestBody)
            .then(result => {

                if (result.data.autoData[0] !== undefined) {

                    setAutoData(result.data.autoData[0])
                } else {
                    setAutoData(Fields)
                }

                if (result.data.previousWeekDataAuto[0] !== undefined) {
                    setPreviousWeekAutoData(result.data.previousWeekDataAuto[0])
                } else {
                    setPreviousWeekAutoData(Fields)
                }

                if (result.data.recData[0] !== undefined) {
                    setRecData(result.data.recData[0])
                } else {
                    setRecData(Fields)
                }

                if (result.data.previousWeekDataRec[0] !== undefined) {
                    setPreviousWeekRecData(result.data.previousWeekDataRec[0])
                } else {
                    setPreviousWeekRecData(Fields)
                }

                if (result.data.usedData[0] !== undefined) {
                    setUsedData(result.data.usedData[0])
                } else {
                    setUsedData(Fields)
                }

                if (result.data.previousWeekDataUsed[0] !== undefined) {
                    setPreviousWeekUsedData(result.data.previousWeekDataUsed[0])
                } else {
                    setPreviousWeekUsedData(Fields)
                }


                if (result.data.cmaxxData[0] !== undefined) {
                    setCreditmaxxData(result.data.cmaxxData[0])
                } else {

                    setCreditmaxxData(Fields)

                }

                if (result.data.previousWeekDataCmaxx[0] !== undefined) {
                    setPreviousWeekCreditmaxxData(result.data.previousWeekDataCmaxx[0])
                } else {
                    setPreviousWeekCreditmaxxData(Fields)
                }

                if (result.data.oneearthData[0] !== undefined) {
                    setOneearthData(result.data.oneearthData[0])
                } else {
                    setOneearthData(Fields)
                }

                if (result.data.previousWeekDataOneearth[0] !== undefined) {
                    setOneearthPreviousWeekData(result.data.previousWeekDataCmaxx[0])
                } else {
                    setOneearthPreviousWeekData(Fields)
                }



            })
            .catch(err => {
                console.log(err);
            });


    };

    const getAutoData = (year, month) => {
        const requestBody = {
            year: year,
            month: month,
            division: "auto"
        }
        const stringifiedYear = getFiscals(month, year);
        const requestBodyBudget = {
            fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
            month: month,
            division: "auto"
        };
        axios.all([
            axios.post(Links.ceoDashboardOprationsDailyDataAuto, requestBody),
            axios.post(Links.ceoDashboardOprationsBudgetDataAuto, requestBodyBudget)
        ])
            .then(axios.spread((data1, data2) => {
                setDailyDataThisMonthAuto(data1.data.dailyDataThisMonth[0]);
                setBudgetThisMonthAuto(data2.data.budgetThisMonth[0]);
            }))
            .catch((err) => {
                console.log(err);
            });
    };

    const getUsedData = (year, month) => {
        const requestBody = {
            year: year,
            month: month,
            division: "used"
        }
        const stringifiedYear = getFiscals(month, year);
        const requestBodyBudget = {
            fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
            month: month,
            division: "used"
        };
        axios.all([
            axios.post(Links.ceoDashboardOprationsDailyDataUsed, requestBody),
            axios.post(Links.ceoDashboardOprationsBudgetDataUsed, requestBodyBudget)
        ])
            .then(axios.spread((data1, data2) => {
                setDailyDataThisMonthUsed(data1.data.dailyDataThisMonth[0]);
                setBudgetThisMonthUsed(data2.data.budgetThisMonth[0]);
            }))
            .catch((err) => {
                console.log(err);
            });
    };

    const getRecData = (year, month) => {
        const requestBody = {
            year: year,
            month: month,
            division: "rec"
        }
        const stringifiedYear = getFiscals(month, year);
        const requestBodyBudget = {
            fiscal_year: "FY" + stringifiedYear.toString().slice(-2),
            month: month,
            division: "rec"
        };
        axios.all([
            axios.post(Links.ceoDashboardOprationsDailyDataRec, requestBody),
            axios.post(Links.ceoDashboardOprationsBudgetDataRec, requestBodyBudget)
        ])
            .then(axios.spread((data1, data2) => {
                setDailyDataThisMonthRec(data1.data.dailyDataThisMonth[0]);
                setBudgetThisMonthRec(data2.data.budgetThisMonth[0]);
            }))
            .catch((err) => {
                console.log(err);
            });
    };

    const getCreditMaxxData = () => {
        let filterVal = {
            store_id: 47,
            month: month,
            year: year,
            fiscal_year: 'FY' + getFiscals(month, year).toString().substring(2)
        }
        axios.all([
            axios.post(Links.getDivisionalReportForFF, filterVal),
            axios.post(Links.getFilteredBudgetForDivisionalReportForFF, filterVal)
        ])
            .then(axios.spread((data1, data2) => {
                setDailyDataThisMonthCreditmaxx(data1.data.dailyDataThisMonth[0]);
                setBudgetThisMonthCreditmaxx(data2.data.budgetThisMonth[0]);
            }))
            .catch((err) => {
                console.log(err);
            });
    };

    const getOneEarthData = () => {
        let filterVal = {
            store_id: 48,
            month: month,
            year: year,
            fiscal_year: 'FY' + getFiscals(month, year).toString().substring(2)
        }
        axios.all([
            axios.post(Links.getDivisionalReportForFF, filterVal),
            axios.post(Links.getFilteredBudgetForDivisionalReportForFF, filterVal)
        ])
            .then(axios.spread((data1, data2) => {
                setDailyDataThisMonthOneEarth(data1.data.dailyDataThisMonth[0]);
                setBudgetThisMonthOneEarth(data2.data.budgetThisMonth[0]);
            }))
            .catch((err) => {
                console.log(err);
            });
    }


    return (
        <>
            {lineModalVisible && <MultiLineChartModal />}
            {totaGrossModalVisible && <DivisionalTotalGrossModal/>}
            {newVehicleModalVisible && <DivisionalNewVehicleModal/>}
            {usedVehicleModalVisible && <DivisionalUsedVehicleModal/>}
            {apptModalVisible && <DivisionalApptModal/>}
            {accountingModal && <AccountingModal/>}
            {accountingColumnModal && <AccountingColumnModal/>}

            <div style={{ margin: "1rem 1rem 1rem 0rem" }}>

                <Grid item xs={12} container spacing={1}>
                    <Grid item container spacing={1}>
                        <Grid item xs={10} sm={6} md={2} xl={1} spacing={1}>
                            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={year}
                                    label="Year"
                                    onChange={handleYearChange}
                                >
                                    {years.map((year, index) => (
                                        <MenuItem key={index} value={year}>{year}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10} sm={6} md={2} xl={1} spacing={1}>
                            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                                <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={month}
                                    label="Age"
                                    onChange={handleMonthChange}
                                >
                                    {Object.values(MONTHS).map((month, index) => (
                                        <MenuItem key={index} value={month}>{month}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={10} sm={6} md={2} xl={1} spacing={1}>
                            <FormControl variant="standard" sx={{ m: 1, width: "100%" }}>
                                <InputLabel id="demo-simple-select-label">Week</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={week}
                                    label="Age"
                                    onChange={handleWeekChange}
                                >
                                    {Object.values(WEEKS).map((week, index) => (
                                        <MenuItem key={index} value={week}>Week {week}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item className="mt-3">
                            <button className={`p-2 mt-1 btn btn-secondary mx-3 ${buttonFlash === true && "button-glow"}`} type="submit"
                                onClick={() => {
                                    setButtonFlash(false);
                                    showResults()
                                }}
                            >Show Details</button>
                        </Grid>
                    </Grid>

                </Grid>
                <div style={{ borderRadius: 4, padding: 5, marginTop: 50, border: '0px solid Numberadadad' }} >
                    <div className="d-flex d-flex align-items-center">
                        <h4 className="p-0 m-0">Defense Report:</h4>
                        <p className="p-0 my-0 mx-4" style={{ fontWeight: 'bold' }}>Accounting as at {month} Week {week}</p>
                        <div className='mx-2 d-flex align-items-center' >
                            <div className='d-flex'>
                                <div className='mx-1' style={{ height: 19, width: 19, backgroundColor: "#084298" }}></div>
                                <div>Data as at</div>
                            </div>
                            <div className='d-flex mx-3'>
                                <div className='mx-1' style={{ height: 19, width: 19, backgroundColor: "#000" }}></div>
                                <div>Data MTD</div>
                            </div>
                        </div>
                    </div>
                    <table
                        className="table table-bordered border-black table-fit"
                        style={{ marginTop: 20, marginBottom: 0 }}
                    >
                        <thead>
                            <tr className="text-center">
                                <th
                                    colSpan="1"
                                    className=""
                                    style={{ backgroundColor: "orange", fontSize: "1rem", width: "2%" }}
                                >
                                </th>
                                {(allAccess?.master === 1 || allAccess?.accountingAuto === 1 || allAccess?.accountingRec === 1 || allAccess?.accountingUsed === 1 || allAccess?.accountingCreditmaxx === 1) && <th
                                    colSpan="1"
                                    className="ceo-header"
                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                    width="100px"
                                >
                                    Accounting
                                </th>}
                                {(allAccess?.master === 1 || allAccess?.hrAuto === 1 || allAccess?.hrRec === 1 || allAccess?.hrUsed === 1 || allAccess?.hrCreditmaxx === 1) && <th
                                    colSpan="1"
                                    className="ceo-header"
                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                >
                                    HR
                                </th>}
                                {(allAccess?.master === 1 || allAccess?.marketingAuto === 1 || allAccess?.marketingRec === 1 || allAccess?.marketingUsed === 1 || allAccess?.marketingCreditmaxx === 1) && <th
                                    colSpan="1"
                                    className="ceo-header"
                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                >
                                    Marketing
                                </th>}
                                {(allAccess?.master === 1 || allAccess?.operationsAuto === 1 || allAccess?.operationsRec === 1 || allAccess?.operationsUsed === 1 || allAccess?.operationsCreditmaxx === 1 || allAccess?.operationsOneearth === 1) && <th
                                    colSpan="1"
                                    className="ceo-header"
                                    style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                                >
                                    Operations
                                </th>}
                            </tr>
                        </thead>
                        <tbody>
                            {(allAccess?.master === 1 || allAccess?.auto === 1) && <tr>
                                <td
                                    style={{
                                        padding: "10px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                        width: 200
                                    }}
                                    className={`${autoData?.diff !== null && autoData?.diff <= 3 && autoData?.locked_user !== "Undefined" && userName !== autoData?.locked_user && "locked-user"}`}
                                >
                                    <div className="m-2">Auto</div>
                                    {autoData?.diff !== null && autoData?.diff <= 3 && autoData?.locked_user !== "Undefined" && userName !== autoData?.locked_user ?
                                        <button className="button m-2 bg-danger text-white">{autoData?.locked_user}</button>
                                        :
                                        autoEdit === false ?
                                            autoData.id > 0 ?
                                                <button className="button m-2" onClick={() => { CheckIfEditAvailable("auto", "edit") }}>Edit</button>
                                                :
                                                <button className="button m-2" onClick={() => { CheckIfEditAvailable("auto", "add") }}>Add</button>
                                            : <button onClick={() => { AddData("auto") }} className="button m-2">Save</button>
                                    }

                                </td>
                                {(allAccess?.master === 1 || allAccess?.accountingAuto === 1) &&
                                    <td
                                        style={{
                                            padding: "0px",
                                            textAlign: "center",
                                            fontSize: "1.25rem",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <table
                                            className="table table-bordered border-black table-fit"
                                            style={{ marginBottom: 0 }}
                                        >
                                            <thead>
                                                <tr className="text-center">
                                                    <th
                                                        colSpan="1"
                                                        className="ceo-header"
                                                        style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                    >
                                                        Category
                                                    </th>
                                                    <th
                                                        colSpan="1"
                                                        className="ceo-header"
                                                        style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                    >
                                                        Actual
                                                    </th>
                                                    <th
                                                        colSpan="1"
                                                        className="ceo-header"
                                                        style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                    >
                                                        Target
                                                    </th>
                                                    <th
                                                        colSpan="1"
                                                        className="ceo-header"
                                                        style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                    >
                                                        Last Week
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <DataFieldsAccounting modalName="cash_balance" name="Cash Balance" textColor="#084298" actualValue={autoAccountingData.cash_balance} targetValue={autoAccountingData.cash_balance_target} previousValue={autoAccountingPreviousWeekData.cash_balance} onChangeName="cash_balance" isEdit={autoEdit} division="auto" sign="$" />
                                                <DataFieldsAccounting modalName="used_inventory_120_units" name="Used Inventory 120+ Days - Units" textColor="#084298" actualValue={autoAccountingData.used_inventory_90_units} targetValue={0} previousValue={autoAccountingPreviousWeekData.used_inventory_90_units} onChangeName="used_inventory_90_units" isEdit={autoEdit} division="auto" />
                                                <DataFieldsAccounting modalName="used_inventory_120" name="Used Inventory 120+ Days" textColor="#084298" actualValue={autoAccountingData.used_inventory_90} targetValue={0} previousValue={autoAccountingPreviousWeekData.used_inventory_90} onChangeName="used_inventory_90" isEdit={autoEdit} division="auto" sign="$" />
                                                <DataFieldsAccounting modalName="cash_inventory_45" name="Cash Inventory 45+ Days" textColor="#084298" actualValue={autoAccountingData.cash_inventory_45} targetValue={0} previousValue={autoAccountingPreviousWeekData.cash_inventory_45} onChangeName="cash_inventory_45" isEdit={autoEdit} division="auto" sign="$" />
                                                <DataFieldsAccounting modalName="cash_inventory_all" name="Cash Inventory - All" textColor="#084298" actualValue={autoAccountingData.cash_inventory_all} targetValue={autoAccountingData.cash_inventory_all_target} previousValue={autoAccountingPreviousWeekData.cash_inventory_all} onChangeName="cash_inventory_all" isEdit={autoEdit} division="auto" sign="$" />
                                                <DataFieldsAccounting modalName="part_inventory" name="Part Inventory (1 yr)" textColor="#084298" actualValue={autoAccountingData.part_inventory} targetValue={autoAccountingData.part_inventory_target} previousValue={autoAccountingPreviousWeekData.part_inventory} onChangeName="part_inventory" isEdit={autoEdit} division="auto" sign="$" />
                                                <DataFieldsAccounting modalName="cit_10" name="CIT's 10+ Days" textColor="#084298" actualValue={autoAccountingData.CIT_10_plus} targetValue={autoAccountingData.CIT_10_plus_target} previousValue={autoAccountingPreviousWeekData.CIT_10_plus} onChangeName="CIT_10_plus" isEdit={autoEdit} division="auto" sign="$" />
                                                <button className="button m-2" style={{float:"left"}} onClick={() => { 
setDivisionAccounting("Auto");
setAccountingModal(true);

                                             }}>Edit</button>
                                            </tbody>
                                        </table>
                                    </td>

                                }
                                {(allAccess?.master === 1 || allAccess?.hrAuto === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFields name="Head Count" textColor="#084298" actualValue={autoData.head_count} targetValue={autoData.head_count_target} onChangeName="head_count" isEdit={autoEdit} division="auto" />
                                            <DataFields name="Current Vacancies" textColor="#084298" actualValue={autoData.current_posting} targetValue={autoData.current_posting_target} onChangeName="current_posting" isEdit={autoEdit} division="auto" />
                                            <DataFields name="Turnover" textColor="#084298" actualValue={autoData.turnover} targetValue={autoData.turnover_target} onChangeName="turnover" isEdit={autoEdit} division="auto" sign="%" />
                                            <DataFields name="Rev. Per Emp." actualValue={autoData.rev_per_emp} targetValue={autoData.rev_per_emp_target} onChangeName="rev_per_emp" isEdit={autoEdit} division="auto" sign="$" />
                                            <DataFields name="Gross Per Emp." actualValue={autoData.gross_per_emp} targetValue={autoData.gross_per_emp_target} onChangeName="gross_per_emp" isEdit={autoEdit} division="auto" sign="$" />
                                        </tbody>
                                    </table>
                                </td>

                                }
                                {(allAccess?.master === 1 || allAccess?.marketingAuto === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {/* <ReturnOptions name="Users Actual" />
                                <ReturnOptions name="Users Target" />
                                <ReturnOptions name="Leads Actual" />
                                <ReturnOptions name="Leads Target" /> */}

                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                    width="100px"
                                                >
                                                    Monthly Pace
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFieldsMarketing name="Unique Visitors" actualValue={autoData.unique_visitors} targetValue={autoData.unique_visitors_target} onChangeName="unique_visitors" isEdit={autoEdit} division="auto" />
                                            <DataFieldsMarketing name="VDP Views" actualValue={autoData.vdp_views} targetValue={autoData.vdp_views_target} onChangeName="vdp_views" isEdit={autoEdit} division="auto" />
                                            <DataFieldsMarketing name="Internet Leads" actualValue={autoData.internet_leads} targetValue={autoData.internet_leads_target} onChangeName="internet_leads" isEdit={autoEdit} division="auto" />
                                            <DataFieldsMarketing name="Total Leads" actualValue={autoData.total_leads} targetValue={autoData.total_leads_target} onChangeName="total_leads" isEdit={autoEdit} division="auto" />
                                            <p className="mt-2 text-start p-2 pb-0">FFUN.COM</p>
                                            <DataFieldsMarketing name="Unique Visitors" actualValue={autoData?.unique_visitors_ffun} targetValue={autoData?.unique_visitors_ffun_target} onChangeName="unique_visitors_ffun" isEdit={autoEdit} division="auto" />
                                            <DataFieldsMarketing name="VDP Views" actualValue={autoData?.vdp_views_ffun} targetValue={autoData?.vdp_views_ffun_target} onChangeName="vdp_views_ffun" isEdit={autoEdit} division="auto" />
                                            <DataFieldsMarketing name="Total Leads" actualValue={autoData?.total_leads_ffun} targetValue={autoData?.total_leads_ffun_target} onChangeName="total_leads_ffun" isEdit={autoEdit} division="auto" />
                                        </tbody>
                                    </table>
                                </td>

                                }
                                {(allAccess?.master === 1 || allAccess?.operationsAuto === 1) &&
                                    <td
                                        style={{
                                            padding: "0px",
                                            textAlign: "center",
                                            fontSize: "1.25rem",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {Object.keys(dailyDataThisMonthAuto).length > 0 &&
                                            <Operations dailyData={dailyDataThisMonthAuto} budgetData={budgetThisMonthAuto} division="auto" />}
                                    </td>
                                }
                            </tr>}


                            {(allAccess?.master === 1 || allAccess?.rec === 1) && <tr>
                                <td
                                    style={{
                                        padding: "10px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                        width: 200
                                    }}
                                    className={`${recData?.diff !== null && recData?.diff <= 3 && recData?.locked_user !== "Undefined" && userName !== recData?.locked_user && "locked-user"}`}
                                >
                                    <div className="m-2">Rec</div>

                                    {recData?.diff !== null && recData?.diff <= 3 && recData?.locked_user !== "Undefined" && userName !== recData?.locked_user ?
                                        <button className="button m-2 bg-danger text-white">{recData?.locked_user}</button>
                                        :
                                        recEdit === false ?
                                            recData.id > 0 ?
                                                <button className="button m-2" onClick={() => { CheckIfEditAvailable("rec", "edit") }}>Edit</button>
                                                :
                                                <button className="button m-2" onClick={() => { CheckIfEditAvailable("rec", "add") }}>Add</button>
                                            : <button onClick={() => { AddData("rec") }} className="button m-2">Save</button>
                                    }
                                </td>
                                {(allAccess?.master === 1 || allAccess?.accountingRec === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >

                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Last Week
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <DataFieldsAccounting  modalName="cash_balance"  name="Cash Balance" textColor="#084298" actualValue={recAccountingData.cash_balance} targetValue={recAccountingData.cash_balance_target} previousValue={recAccountingPreviousWeekData.cash_balance} onChangeName="cash_balance" isEdit={recEdit} division="rec" sign="$" />
                                            <DataFieldsAccounting modalName="used_inventory_120_units"  name="Used Inventory 120+ Days - Units" textColor="#084298" actualValue={recAccountingData.used_inventory_90_units} targetValue={0} previousValue={recAccountingPreviousWeekData.used_inventory_90_units} onChangeName="used_inventory_90_units" isEdit={recEdit} division="rec" />
                                            <DataFieldsAccounting modalName="used_inventory_120"  name="Used Inventory 120+ Days" textColor="#084298" actualValue={recAccountingData.used_inventory_90} targetValue={0} previousValue={recAccountingPreviousWeekData.used_inventory_90} onChangeName="used_inventory_90" isEdit={recEdit} division="rec" sign="$" />
                                            <DataFieldsAccounting modalName="cash_inventory_45"  name="Cash Inventory 45+ Days" textColor="#084298" actualValue={recAccountingData.cash_inventory_45} targetValue={0} previousValue={recAccountingPreviousWeekData.cash_inventory_45} onChangeName="cash_inventory_45" isEdit={recEdit} division="rec" sign="$" />
                                            <DataFieldsAccounting  modalName="cash_inventory_all" name="Cash Inventory - All" textColor="#084298" actualValue={recAccountingData.cash_inventory_all} targetValue={recAccountingData.cash_inventory_all_target} previousValue={recAccountingPreviousWeekData.cash_inventory_all} onChangeName="cash_inventory_all" isEdit={recEdit} division="rec" sign="$" />
                                            <DataFieldsAccounting  modalName="part_inventory" name="Part Inventory (1.5 yr)" textColor="#084298" actualValue={recAccountingData.part_inventory} targetValue={recAccountingData.part_inventory_target} previousValue={recAccountingPreviousWeekData.part_inventory} onChangeName="part_inventory" isEdit={recEdit} division="rec" sign="$" />
                                            <DataFieldsAccounting  modalName="cit_10" name="CIT's 10+ Days" textColor="#084298" actualValue={recAccountingData.CIT_10_plus} targetValue={recAccountingData.CIT_10_plus_target} previousValue={recAccountingPreviousWeekData.CIT_10_plus} onChangeName="CIT_10_plus" isEdit={recEdit} division="rec" sign="$" />
                                      
                                            <button className="button m-2" style={{float:"left"}} onClick={() => { 
setDivisionAccounting("Rec");
setAccountingModal(true);

                                             }}>Edit</button>

                                             
                                               
                                        </tbody>
                                    </table>

                                </td>
                                }
                                {(allAccess?.master === 1 || allAccess?.hrRec === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFields name="Head Count" actualValue={recData.head_count} targetValue={recData.head_count_target} onChangeName="head_count" isEdit={recEdit} division="rec" />
                                            <DataFields name="Current Vacancies" actualValue={recData.current_posting} targetValue={recData.current_posting_target} onChangeName="current_posting" isEdit={recEdit} division="rec" />
                                            <DataFields name="Turnover" actualValue={recData.turnover} targetValue={recData.turnover_target} onChangeName="turnover" isEdit={recEdit} division="rec" sign="%" />
                                            <DataFields name="Rev. Per Emp." actualValue={recData.rev_per_emp} targetValue={recData.rev_per_emp_target} onChangeName="rev_per_emp" isEdit={recEdit} division="rec" />
                                            <DataFields name="Gross Per Emp." actualValue={recData.gross_per_emp} targetValue={recData.gross_per_emp_target} onChangeName="gross_per_emp" isEdit={recEdit} division="rec" />
                                        </tbody>
                                    </table>
                                </td>
                                }
                                {(allAccess?.master === 1 || allAccess?.marketingRec === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                    width="100px"
                                                >
                                                    Monthly Pace
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFieldsMarketing name="Unique Visitors" actualValue={recData.unique_visitors} targetValue={recData.unique_visitors_target} onChangeName="unique_visitors" isEdit={recEdit} division="rec" />
                                            <DataFieldsMarketing name="VDP Views" actualValue={recData.vdp_views} targetValue={recData.vdp_views_target} onChangeName="vdp_views" isEdit={recEdit} division="rec" />
                                            <DataFieldsMarketing name="Internet Leads" actualValue={recData.internet_leads} targetValue={recData.internet_leads_target} onChangeName="internet_leads" isEdit={recEdit} division="rec" />
                                            <DataFieldsMarketing name="Total Leads" actualValue={recData.total_leads} targetValue={recData.total_leads_target} onChangeName="total_leads" isEdit={recEdit} division="rec" />
                                        </tbody>
                                    </table>
                                </td>
                                }
                                {(allAccess?.master === 1 || allAccess?.operationsRec === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {Object.keys(dailyDataThisMonthRec).length > 0 &&
                                        <Operations dailyData={dailyDataThisMonthRec} budgetData={budgetThisMonthRec} division="rec" />}

                                </td>
                                }
                            </tr>}

                            {(allAccess?.master === 1 || allAccess?.used === 1) && <tr>
                                <td
                                    style={{
                                        padding: "10px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                        width: 200
                                    }}
                                    className={`${usedData?.diff !== null && usedData?.diff <= 3 && usedData?.locked_user !== "Undefined" && userName !== usedData?.locked_user && "locked-user"}`}
                                >
                                    <div className="m-2">Used</div>
                                    {usedData?.diff !== null && usedData?.diff <= 3 && usedData?.locked_user !== "Undefined" && userName !== usedData?.locked_user ?
                                        <button className="button m-2 bg-danger text-white">{usedData?.locked_user}</button>
                                        :
                                        usedEdit === false ?
                                            usedData.id > 0 ?
                                                <button className="button m-2" onClick={() => { CheckIfEditAvailable("used", "edit") }}>Edit</button>
                                                :
                                                <button className="button m-2" onClick={() => { CheckIfEditAvailable("used", "add") }}>Add</button>
                                            : <button onClick={() => { AddData("used") }} className="button m-2">Save</button>
                                    }
                                </td>
                                {(allAccess?.master === 1 || allAccess?.accountingUsed === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {/* <ReturnOptions name="Cash" />
                                <ReturnOptions name="Inventory" />
                                <ReturnOptions name="A/R" />
                                <ReturnOptions name="CIT" /> */}
                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>

                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Last Week
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <DataFieldsAccounting  modalName="cash_balance" name="Cash Balance" textColor="#084298" actualValue={usedAccountingData.cash_balance} targetValue={usedAccountingData.cash_balance_target} previousValue={usedAccountingPreviousWeekData.cash_balance} onChangeName="cash_balance" isEdit={usedEdit} division="used" sign="$" />
                                            <DataFieldsAccounting  modalName="used_inventory_120_units" name="Used Inventory 120+ Days - Units" textColor="#084298" actualValue={usedAccountingData.used_inventory_90_units} targetValue={0} previousValue={usedAccountingPreviousWeekData.used_inventory_90_units} onChangeName="used_inventory_90_units" isEdit={usedEdit} division="used" />
                                            <DataFieldsAccounting  modalName="used_inventory_120" name="Used Inventory 120+ Days" textColor="#084298" actualValue={usedAccountingData.used_inventory_90} targetValue={0} previousValue={usedAccountingPreviousWeekData.used_inventory_90} onChangeName="used_inventory_90" isEdit={usedEdit} division="used" sign="$" />
                                            <DataFieldsAccounting  modalName="cash_inventory_45" name="Cash Inventory 45+ Days" textColor="#084298" actualValue={usedAccountingData.cash_inventory_45} targetValue={0} previousValue={usedAccountingPreviousWeekData.cash_inventory_45} onChangeName="cash_inventory_45" isEdit={usedEdit} division="used" sign="$" />
                                            <DataFieldsAccounting  modalName="cash_inventory_all" name="Cash Inventory - All" textColor="#084298" actualValue={usedAccountingData.cash_inventory_all} targetValue={usedAccountingData.cash_inventory_all_target} previousValue={usedAccountingPreviousWeekData.cash_inventory_all} onChangeName="cash_inventory_all" isEdit={usedEdit} division="used" sign="$" />
                                            <DataFieldsAccounting  modalName="cit_10" name="CIT's 10+ Days" textColor="#084298" actualValue={usedAccountingData.CIT_10_plus} targetValue={usedAccountingData.CIT_10_plus_target} previousValue={usedAccountingPreviousWeekData.CIT_10_plus} onChangeName="CIT_10_plus" isEdit={usedEdit} division="used" sign="$" />
                                       
                                            <button className="button m-2" style={{float:"left"}} onClick={() => { 
setDivisionAccounting("Used");
setAccountingModal(true);

                                             }}>Edit</button>
                                        </tbody>
                                    </table>
                                </td>
                                }
                                {(allAccess?.master === 1 || allAccess?.hrUsed === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFields name="Head Count" actualValue={usedData.head_count} targetValue={usedData.head_count_target} onChangeName="head_count" isEdit={usedEdit} division="used" />
                                            <DataFields name="Current Vacancies" actualValue={usedData.current_posting} targetValue={usedData.current_posting_target} onChangeName="current_posting" isEdit={usedEdit} division="used" />
                                            <DataFields name="Turnover" actualValue={usedData.turnover} targetValue={usedData.turnover_target} onChangeName="turnover" isEdit={usedEdit} division="used" sign="%" />
                                            <DataFields name="Rev. Per Emp." actualValue={usedData.rev_per_emp} targetValue={usedData.rev_per_emp_target} onChangeName="rev_per_emp" isEdit={usedEdit} division="used" />
                                            <DataFields name="Gross Per Emp." actualValue={usedData.gross_per_emp} targetValue={usedData.gross_per_emp_target} onChangeName="gross_per_emp" isEdit={usedEdit} division="used" />
                                        </tbody>
                                    </table>
                                </td>
                                }
                                {(allAccess?.master === 1 || allAccess?.marketingUsed === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                    width="100px"
                                                >
                                                    Monthly Pace
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFieldsMarketing name="Unique Visitors" actualValue={usedData.unique_visitors} targetValue={usedData.unique_visitors_target} onChangeName="unique_visitors" isEdit={usedEdit} division="used" />
                                            <DataFieldsMarketing name="VDP Views" actualValue={usedData.vdp_views} targetValue={usedData.vdp_views_target} onChangeName="vdp_views" isEdit={usedEdit} division="used" />
                                            <DataFieldsMarketing name="Internet Leads" actualValue={usedData.internet_leads} targetValue={usedData.internet_leads_target} onChangeName="internet_leads" isEdit={usedEdit} division="used" />
                                            <DataFieldsMarketing name="Total Leads" actualValue={usedData.total_leads} targetValue={usedData.total_leads_target} onChangeName="total_leads" isEdit={usedEdit} division="used" />
                                        </tbody>
                                    </table>
                                </td>
                                }
                                {(allAccess?.master === 1 || allAccess?.operationsUsed === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {Object.keys(dailyDataThisMonthUsed).length > 0 &&
                                        <OperationsUsed dailyData={dailyDataThisMonthUsed} budgetData={budgetThisMonthUsed} division="used" />}
                                </td>
                                }
                            </tr>}

                            {(allAccess?.master === 1 || allAccess?.creditmaxx === 1) && <tr>
                                <td
                                    style={{
                                        padding: "10px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                        width: 200
                                    }}
                                    className={`${creditmaxxData?.diff !== null && creditmaxxData?.diff <= 3 && creditmaxxData?.locked_user !== "Undefined" && userName !== creditmaxxData?.locked_user && "locked-user"}`}
                                >
                                    <div className="m-2">Credit MAXX</div>
                                    {creditmaxxData?.month === month.toString() && creditmaxxData?.year === year.toString() && creditmaxxData?.week === week.toString() ?
                                        creditmaxxData?.diff !== null && creditmaxxData?.diff <= 3 && creditmaxxData?.locked_user !== "Undefined" && userName !== creditmaxxData?.locked_user ?
                                            <button className="button m-2 bg-danger text-white">{creditmaxxData?.locked_user}</button>
                                            :
                                            creditmaxxEdit === false ?
                                                creditmaxxData.id > 0 ?
                                                    <button className="button m-2" onClick={() => { CheckIfEditAvailable("cmaxx", "edit") }}>Edit</button>
                                                    :
                                                    <button className="button m-2" onClick={() => { CheckIfEditAvailable("cmaxx", "add") }}>Add</button>
                                                : <button onClick={() => { AddData("cmaxx") }} className="button m-2">Save</button>
                                        :
                                        creditmaxxEdit === false ?
                                            <button className="button m-2" onClick={() => { CheckIfEditAvailable("cmaxx", "add") }}>Add</button>
                                            : <button onClick={() => { AddData("cmaxx") }} className="button m-2">Save</button>
                                    }
                                </td>
                                {(allAccess?.master === 1 || allAccess?.accountingCreditmaxx === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>

                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Last Week
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFieldsAccountingCreditMaxx name="Charge Offs (3-month av)" textColor="#084298" actualValue={creditmaxxData?.charge_off_3 || 0} targetValue={creditmaxxData?.charge_off_3_target || 0} previousValue={creditmaxxPreviousWeekData?.charge_off_3 || 0} onChangeName="charge_off_3" isEdit={creditmaxxEdit} division="cmaxx" sign="%" />
                                            <DataFieldsAccountingCreditMaxx name="Accounts>30 days (3-month av)" textColor="#084298" actualValue={creditmaxxData?.accounts_3 || 0} targetValue={creditmaxxData?.accounts_3_target || 0} previousValue={creditmaxxPreviousWeekData?.accounts_3 || 0} onChangeName="accounts_3" isEdit={creditmaxxEdit} division="cmaxx" sign="%" />
                                            <DataFieldsAccounting name="Number of repos" textColor="#084298" actualValue={creditmaxxData?.no_of_repos || 0} targetValue={creditmaxxData?.no_of_repos_target || 0} previousValue={creditmaxxPreviousWeekData?.no_of_repos || 0} onChangeName="no_of_repos" isEdit={creditmaxxEdit} division="cmaxx" />
                                            <DataFieldsAccountingCreditMaxx name="Deferred accounts" textColor="#084298" actualValue={creditmaxxData?.deferred_accounts || 0} targetValue={creditmaxxData?.deferred_accounts_target || 0} previousValue={creditmaxxPreviousWeekData?.deferred_accounts || 0} onChangeName="deferred_accounts" isEdit={creditmaxxEdit} division="cmaxx" sign="%" />
                                            <DataFieldsAccounting name="Min monthly cash" textColor="#084298" actualValue={creditmaxxData?.min_monthly_cash || 0} targetValue={creditmaxxData?.min_monthly_cash_target || 0} previousValue={creditmaxxPreviousWeekData?.min_monthly_cash || 0} onChangeName="min_monthly_cash" isEdit={creditmaxxEdit} division="cmaxx" sign="$" />
                                            <DataFieldsAccounting name="Monthly opex / number of accounts" textColor="#084298" actualValue={creditmaxxData?.montly_opex || 0} targetValue={creditmaxxData?.montly_opex_target || 0} previousValue={creditmaxxPreviousWeekData?.montly_opex} onChangeName="montly_opex" isEdit={creditmaxxEdit} division="cmaxx" sign="$" />
                                        </tbody>
                                    </table>
                                </td>
                                }
                                {(allAccess?.master === 1 || allAccess?.hrCreditmaxx === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFields name="Head Count" actualValue={creditmaxxData.head_count} targetValue={creditmaxxData.head_count_target} onChangeName="head_count" isEdit={creditmaxxEdit} division="cmaxx" />
                                            <DataFields name="Current Vacancies" actualValue={creditmaxxData.current_posting} targetValue={creditmaxxData.current_posting_target} onChangeName="current_posting" isEdit={creditmaxxEdit} division="cmaxx" />
                                            <DataFields name="Turnover" actualValue={creditmaxxData.turnover} targetValue={creditmaxxData.turnover_target} onChangeName="turnover" isEdit={creditmaxxEdit} division="cmaxx" sign="%" />
                                            <DataFields name="Accounts per collector" actualValue={creditmaxxData.accounts_per_collector} targetValue={creditmaxxData.accounts_per_collector_target} onChangeName="accounts_per_collector" isEdit={creditmaxxEdit} division="cmaxx" />
                                            <DataFields name="Apps per adjudicator" actualValue={creditmaxxData.apps_per_adjudicator} targetValue={creditmaxxData.apps_per_adjudicator_target} onChangeName="apps_per_adjudicator" isEdit={creditmaxxEdit} division="cmaxx" />
                                        </tbody>
                                    </table>
                                </td>
                                }
                                {(allAccess?.master === 1 || allAccess?.marketingCreditmaxx === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                    width="100px"
                                                >
                                                    Monthly Pace
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFieldsMarketing
                                                name="Applications"
                                                actualValue={dailyDataThisMonthCreditmaxx.total_apps}
                                                targetValue={budgetThisMonthCreditmaxx?.apps || 0} />
                                        </tbody>
                                    </table>
                                </td>
                                }
                                {(allAccess?.master === 1 || allAccess?.operationsCreditmaxx === 1) && <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                                                    width="100px"
                                                >
                                                    Monthly Pace
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFieldsMarketing
                                                name="Booked deals"
                                                actualValue={dailyDataThisMonthCreditmaxx.total_deals}
                                                targetValue={budgetThisMonthCreditmaxx?.deals || 0}
                                            />
                                            {/* <DataFieldsMarketing
                                            name="Expected return of booked deals"
                                            actualValue={creditmaxxData.expected_return}
                                            targetValue={creditmaxxData.expected_return_target}
                                            onChangeName="expected_return"
                                            isEdit={creditmaxxEdit}
                                            division="cmaxx"
                                            sign="%"
                                            prevText="> "
                                        /> */}
                                        </tbody>
                                    </table>

                                </td>
                                }
                            </tr>}

                            {(allAccess?.master === 1 || allAccess?.operationsOneearth === 1) && <tr>
                                <td
                                    style={{
                                        padding: "10px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                        width: 200
                                    }}
                                    className={`${oneearthData?.diff !== null && oneearthData?.diff <= 3 && oneearthData?.locked_user !== "Undefined" && userName !== oneearthData?.locked_user && "locked-user"}`}
                                >
                                    <div className="m-2">One Earth</div>
                                    {/* {oneearthData?.month === month.toString() && oneearthData?.year === year.toString() && oneearthData?.week === week.toString() ?
                                    oneearthData?.diff !== null && oneearthData?.diff <= 3 && oneearthData?.locked_user !== "Undefined" && userName !== oneearthData?.locked_user ?
                                        <button className="button m-2 bg-danger text-white">{oneearthData?.locked_user}</button>
                                        :
                                        oneearthEdit === false ?
                                            oneearthData.id > 0 ?
                                                <button className="button m-2" onClick={() => { CheckIfEditAvailable("oneearth", "edit") }}>Edit</button>
                                                :
                                                <button className="button m-2" onClick={() => { CheckIfEditAvailable("oneearth", "add") }}>Add</button>
                                            : <button onClick={() => { AddData("oneearth") }} className="button m-2">Save</button>
                                    :
                                    oneearthEdit === false ?
                                        <button className="button m-2" onClick={() => { CheckIfEditAvailable("oneearth", "add") }}>Add</button>
                                        : <button onClick={() => { AddData("oneearth") }} className="button m-2">Save</button>
                                } */}
                                </td>

                                <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >

                                </td>
                                <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >

                                </td>
                                <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >

                                </td>
                                <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >

                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#a2c1a2", fontSize: "1rem" }}
                                                    width="100px"
                                                >
                                                    Monthly Pace
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFieldsMarketing
                                                name="OEPP"
                                                actualValue={dailyDataThisMonthOneEarth.total_ffun_adv}
                                                targetValue={budgetThisMonthOneEarth?.total_ffun_adv || 0}
                                            />
                                            <DataFieldsMarketing
                                                name="Warranties"
                                                actualValue={dailyDataThisMonthOneEarth.total_warranty}
                                                targetValue={budgetThisMonthOneEarth?.total_warranty || 0}
                                            />
                                            <DataFieldsMarketing
                                                name="GAP"
                                                actualValue={dailyDataThisMonthOneEarth.total_gap}
                                                targetValue={budgetThisMonthOneEarth?.total_gap || 0} />
                                            <DataFieldsMarketing
                                                name="Creditor"
                                                actualValue={dailyDataThisMonthOneEarth.total_creditor}
                                                targetValue={budgetThisMonthOneEarth?.total_creditor || 0} />
                                        </tbody>
                                    </table>
                                </td>
                            </tr>}

                            {(allAccess?.master === 1 || allAccess?.total === 1) && <tr>
                                <td
                                    style={{
                                        padding: "10px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                        width: 200
                                    }}
                                // className={`${getTimeDifferenceInMinutes(creditmaxxData?.transaction_locked_at, new Date()) === true && userName !== creditmaxxData?.locked_user && "locked-user"}`}
                                >
                                    Totals
                                </td>
                                <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >

                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>

                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b1d5dd", fontSize: "1rem" }}
                                                >
                                                    Last Week
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFieldsAccounting
                                                name="Cash Balance"
                                                textColor="#084298"
                                                actualValue={(parseInt(autoData?.cash_balance) + parseInt(usedData?.cash_balance) + parseInt(recData?.cash_balance))}
                                                targetValue={(parseInt(autoData?.cash_balance_target) + parseInt(usedData?.cash_balance_target) + parseInt(recData?.cash_balance_target))}
                                                previousValue={(parseInt(autoPreviousWeekData?.cash_balance) + parseInt(usedPreviousWeekData?.cash_balance) + parseInt(recPreviousWeekData?.cash_balance))}
                                                onChangeName="cash_balance"
                                                isEdit={autoEdit}
                                                division="all"
                                                sign="$" />
                                        </tbody>
                                    </table>
                                </td>
                                <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <table
                                        className="table table-bordered border-black table-fit"
                                        style={{ marginBottom: 0 }}
                                    >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#8b8bb5", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFields name="Head Count"
                                                actualValue={(parseInt(autoData?.head_count) + parseInt(usedData?.head_count) + parseInt(recData?.head_count) + parseInt(creditmaxxData?.head_count))}
                                                targetValue={(parseInt(autoData?.head_count_target) + parseInt(usedData?.head_count_target) + parseInt(recData?.head_count_target) + parseInt(creditmaxxData?.head_count_target))}
                                            />
                                            <DataFields name="Current Vacancies"
                                                actualValue={(parseInt(autoData?.current_posting) + parseInt(usedData?.current_posting) + parseInt(recData?.current_posting) + parseInt(creditmaxxData?.current_posting))}
                                                targetValue={(parseInt(autoData?.current_posting_target) + parseInt(usedData?.current_posting_target) + parseInt(recData?.current_posting_target) + parseInt(creditmaxxData?.current_posting_target))}
                                            />
                                        </tbody>
                                    </table>
                                </td>
                                <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <table className="table table-bordered border-black table-fit" >
                                        <thead>
                                            <tr className="text-center">
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Category
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Actual
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                >
                                                    Target
                                                </th>
                                                <th
                                                    colSpan="1"
                                                    className="ceo-header"
                                                    style={{ backgroundColor: "#b38bb5", fontSize: "1rem" }}
                                                    width="100px"
                                                >
                                                    Monthly Pace
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <DataFieldsMarketing
                                                name="Unique Visitors"
                                                actualValue={(parseInt(autoData?.unique_visitors) + parseInt(usedData?.unique_visitors) + parseInt(recData?.unique_visitors) + parseInt(creditmaxxData?.unique_visitors))}
                                                targetValue={(parseInt(autoData?.unique_visitors_target) + parseInt(usedData?.unique_visitors_target) + parseInt(recData?.unique_visitors_target) + parseInt(creditmaxxData?.unique_visitors_target))}
                                            />
                                            <DataFieldsMarketing
                                                name="VDP Views"
                                                actualValue={(parseInt(autoData?.vdp_views) + parseInt(usedData?.vdp_views) + parseInt(recData?.vdp_views) + parseInt(creditmaxxData?.vdp_views))}
                                                targetValue={(parseInt(autoData?.vdp_views_target) + parseInt(usedData?.vdp_views_target) + parseInt(recData?.vdp_views_target) + parseInt(creditmaxxData?.vdp_views_target))}
                                            />
                                            <DataFieldsMarketing
                                                name="Internet Leads"
                                                actualValue={(parseInt(autoData?.internet_leads) + parseInt(usedData?.internet_leads) + parseInt(recData?.internet_leads) + parseInt(creditmaxxData?.internet_leads))}
                                                targetValue={(parseInt(autoData?.internet_leads_target) + parseInt(usedData?.internet_leads_target) + parseInt(recData?.internet_leads_target) + parseInt(creditmaxxData?.internet_leads_target))}
                                            />
                                            <DataFieldsMarketing
                                                name="Total Leads"
                                                actualValue={(parseInt(autoData?.total_leads) + parseInt(usedData?.total_leads) + parseInt(recData?.total_leads) + parseInt(creditmaxxData?.total_leads))}
                                                targetValue={(parseInt(autoData?.total_leads_target) + parseInt(usedData?.total_leads_target) + parseInt(recData?.total_leads_target) + parseInt(creditmaxxData?.total_leads_target))}
                                                division="all"
                                            />
                                        </tbody>
                                    </table>
                                </td>
                                <td
                                    style={{
                                        padding: "0px",
                                        textAlign: "center",
                                        fontSize: "1.25rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    <OperationsTotal
                                        dailyData={dailyDataThisMonthAuto}
                                        budgetData={budgetThisMonthAuto}
                                        dailyDataRec={dailyDataThisMonthRec}
                                        budgetDataRec={budgetThisMonthRec}
                                        dailyDataUsed={dailyDataThisMonthUsed}
                                        budgetDataUsed={budgetThisMonthUsed}
                                    />
                                </td>
                            </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
};

export default CeoDashboard;
