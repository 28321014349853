import React, { useEffect, useState } from "react";
import './Demo.css';
import axios from "axios";
import Links from "../../Data/Links";
import CreateRowForUsed from "../../component/Budget/CreateRowForUsed";
import Cookies from 'js-cookie';
import DealerYearMonthSelector from "../../component/Budget/DealerYearMonthSelector";
import TotalRowForUsed from "../../component/Budget/TotalRowForUsed";
import THForUsed from "../../component/DataEntry/THForUsedBudget";
const months = [
    'Sep',
    'Oct',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
]

const BudgetDn = ({ props }) => {
    const [stores, setStores] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
    const [budgetData, setBudgetData] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
    const [filterVal, setFilterVal] = useState({
        store_id: 62,
        store_name: 'Saskatoon',
        division: "FFUN Cars",
        company: 'FFUN Cars',
        year: 'FY25',
    });

    let userData;
    if (Cookies.get('user_data')) {
        userData = JSON.parse(Cookies.get('user_data'));
    }
    useEffect(() => {
        let storeObj = {};
        stores.length > 0 && stores?.map(i => {
            if (i.division === "FFUN Cars") {
                storeObj[i.id] = i.name;
            }

        });
        setStores(storeObj);
        setFilterVal({
            store_id: Object.keys(storeObj)[0],
            store_name: Object.values(storeObj)[0],
            division: "FFUN Cars",
            company: 'FFUN Cars',
            year: 'FY25',
        })
    }, []);

    useEffect(() => {
        loadData();
    }, [filterVal])

    const loadData = (filterData = null) => {
        if (filterData) {
            setFilterVal(filterData);
        }
        axios.post(Links.getYearlyFilteredBudgetForUsed, filterVal)
            .then(res => {
                setBudgetData(res.data.budget)
            }).catch(err => {
                console.log(err);
            });
    }

    let total = {
        used_retail_delivered: 0,
        used_retail_front_GP: 0,
        used_retail_back_GP: 0,
        service_total_hours: 0,
        service_CP_hours: 0,
        service_CP_ROs: 0,
        service_CP_hrs_RO:0,
        service_total_GP: 0,
        appt_made: 0,
        appt_show: 0,
        store_visit: 0,
        lead: 0,
        sales_staff: 0,
        booked: 0,
        no_show: 0,
        phone_up: 0,
        fresh_up: 0,
        internet_lead:0,
        // service_no_of_tech: 0,
        // service_journeyman:0,
        // service_apprentice:0,
        // service_advisors_needed:0,
        service_units_shipped:0,
    };
    return (
        <div style={{ margin: "2rem 2rem 2rem 0rem" }}>
            <DealerYearMonthSelector stores={stores} filterVal={filterVal} setFilterVal={setFilterVal} loadData={() => loadData()} />

            <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
                <THForUsed />
                <tbody>
                    {
                        months.map((i, n) => {
                            total.used_retail_delivered = (budgetData[i] && budgetData[i].used_retail_delivered > 0) ? total.used_retail_delivered + parseInt(budgetData[i].used_retail_delivered) : total.used_retail_delivered;
                            total.used_retail_front_GP = (budgetData[i] && budgetData[i].used_retail_front_GP > 0) ? total.used_retail_front_GP + parseInt(budgetData[i].used_retail_front_GP) : total.used_retail_front_GP;
                            total.used_retail_back_GP = (budgetData[i] && budgetData[i].used_retail_back_GP > 0) ? total.used_retail_back_GP + parseInt(budgetData[i].used_retail_back_GP) : total.used_retail_back_GP;
                            total.service_total_hours = (budgetData[i] && budgetData[i].service_total_hours > 0) ? total.service_total_hours + parseInt(budgetData[i].service_total_hours) : total.service_total_hours;
                            total.service_CP_hours = (budgetData[i] && budgetData[i].service_CP_hours > 0) ? total.service_CP_hours + parseInt(budgetData[i].service_CP_hours) : total.service_CP_hours;
                            total.service_CP_ROs = (budgetData[i] && budgetData[i].service_CP_ROs > 0) ? total.service_CP_ROs + parseInt(budgetData[i].service_CP_ROs) : total.service_CP_ROs;
                            total.service_CP_hrs_RO = (budgetData[i] && budgetData[i].service_CP_hrs_RO > 0) ? total.service_CP_hrs_RO + parseInt(budgetData[i].service_CP_hrs_RO) : total.service_CP_hrs_RO;
                            total.service_total_GP = (budgetData[i] && budgetData[i].service_total_GP > 0) ? total.service_total_GP + parseInt(budgetData[i].service_total_GP) : total.service_total_GP;
                            total.appt_made = (budgetData[i] && budgetData[i].appt_made > 0) ? total.appt_made + parseInt(budgetData[i].appt_made) : total.appt_made;
                            total.appt_show = (budgetData[i] && budgetData[i].appt_show > 0) ? total.appt_show + parseInt(budgetData[i].appt_show) : total.appt_show;
                            total.booked = (budgetData[i] && budgetData[i].booked > 0) ? total.booked + parseInt(budgetData[i].booked) : total.booked;
                            total.store_visit = (budgetData[i] && budgetData[i].store_visit > 0) ? total.store_visit + parseInt(budgetData[i].store_visit) : total.store_visit;
                            total.lead = (budgetData[i] && budgetData[i].lead > 0) ? total.lead + parseInt(budgetData[i].lead) : total.lead;
                            // total.service_journeyman = (budgetData[i] && budgetData[i].service_journeyman > 0) ? total.service_journeyman + parseInt(budgetData[i].service_journeyman) : total.service_journeyman;
                            // total.service_apprentice = (budgetData[i] && budgetData[i].service_apprentice > 0) ? total.service_apprentice + parseInt(budgetData[i].service_apprentice) : total.service_apprentice;
                            // total.service_advisors_needed = (budgetData[i] && budgetData[i].service_advisors_needed > 0) ? total.service_advisors_needed + parseInt(budgetData[i].service_advisors_needed) : total.service_advisors_needed;
                            // total.service_no_of_tech = (budgetData[i] && budgetData[i].service_no_of_tech >0) ? total.service_no_of_tech + parseInt(budgetData[i].service_no_of_tech) : total.service_no_of_tech;
                            total.service_units_shipped = (budgetData[i] && budgetData[i].service_units_shipped >0) ? total.service_units_shipped + parseInt(budgetData[i].service_units_shipped) : total.service_units_shipped;
                            total.internet_lead = (budgetData[i] && budgetData[i].internet_lead >0) ? total.internet_lead + parseInt(budgetData[i].internet_lead) : total.internet_lead;
                            total.fresh_up = (budgetData[i] && budgetData[i].fresh_up >0) ? total.fresh_up + parseInt(budgetData[i].fresh_up) : total.fresh_up;
                            total.phone_up = (budgetData[i] && budgetData[i].phone_up >0) ? total.phone_up + parseInt(budgetData[i].phone_up) : total.phone_up;
                            total.sales_staff = (budgetData[i] && budgetData[i].sales_staff >0) ? total.sales_staff + parseInt(budgetData[i].sales_staff) : total.sales_staff;
                            total.lead = total.internet_lead+total.phone_up+total.fresh_up;
            
                            return <CreateRowForUsed row={budgetData} userData={userData} month={i} filterData={filterVal} loadData={() => loadData()} />
                        })

                    }

                    <TotalRowForUsed total={total} />
                </tbody>
            </table>
        </div>
    );
};

export default BudgetDn
