import React, { useEffect, useState } from "react";
import DealerYearMonthSelector from "../../component/Budget/DealerYearMonthSelector";
import './Demo.css';
import axios from "axios";
import Links from "../../Data/Links";
import Cookies from 'js-cookie';
import { store, updateBudgetFFRow, clearBudgetFFRow } from "../../redux";
import { useSelector } from "react-redux";

const months = [
  'Sep',
  'Oct',
  'Nov',
  'Dec',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
]

const BudgetFfunFinancial = () => {
  const [stores, setStores] = useState(localStorage.getItem('stores') ? JSON.parse(localStorage.getItem('stores')) : "");
  const [budgetData, setBudgetData] = useState({});
  const [filterVal, setFilterVal] = useState({
    store_id: 47,
    store_name: 'CreditMaxx',
    division: "FFUN Financial",
    company: 'FFUN',
    year: 'FY25'
  });

  let userData;
  if (Cookies.get('user_data')) {
    userData = JSON.parse(Cookies.get('user_data'));
  }

  useEffect(() => {
    let storeObj = {};
    stores.length > 0 && stores?.map(i => {
      if (i.division === "FFUN Financial") {
        storeObj[i.id] = i.name;
      }
    });
    setStores(storeObj);
    setFilterVal({
      store_id: Object.keys(storeObj)[0],
      store_name: Object.values(storeObj)[0],
      division: "FFUN Financial",
      company: 'FFUN',
      year: 'FY25'
    })
  }, []);

  useEffect(() => {
    loadData();
  }, [filterVal])

  const loadData = (filterData = null) => {
    if (filterData) {
      setFilterVal(filterData);
    }
    axios.post(Links.getYearlyFilteredBudgetForFfunFinancial, filterVal)
      .then(res => {
        setBudgetData(res.data.budget)
      }).catch(err => {
        console.log(err);
      });
  }

  const [edit, setEdit] = useState({});
  const onChangeValue = (event, field) => {
    store.dispatch(updateBudgetFFRow({ [field]: event.target.value ? event.target.value : 0 }));
  }
  //todo

  const SaveRecord = (month, budgetEntryFF) => {
    if (budgetEntryFF.id) {
      axios.patch(Links.updateFfunFinancialBudgetById + '/' + budgetEntryFF.id, { row: budgetEntryFF })
        .then(res => {
          alert("Budget has been updated.");
          setEdit({ [month]: false });
          store.dispatch(clearBudgetFFRow());
          loadData(filterVal);
        }).catch(err => {
          console.log(err);
        });
    } else {
      axios.post(Links.addFfunFinancialBudgetData, budgetEntryFF)
        .then(res => {
          alert("Budget has been added.");
          setEdit({ [month]: false });
          store.dispatch(clearBudgetFFRow());
          loadData(filterVal);
        }).catch(err => {
          console.log(err);
        });
    }
  };

  const DeleteRecord = (month) => {
    if (window.confirm("Are you sure want to delete?")) {
      axios.post(Links.deleteFfunFinancialBudget,
        { id: budgetData[month].id })
        .then(result => {
          alert("Deleted succussfully!");
          loadData(filterVal);
        }).catch(err => {
          console.log(err);
        });
    }
  }

  const CreditMaxxTable = () => {
    const budgetEntryFF = useSelector(state => state.dataEntryBudget.budgetEntryFF);
    const creditMaxxTableFields = ["apps", "approvals", "tier_1", "tier_2", "tier_3", "tier_4", "deals", "deal_gp"];
    return (
      <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
        <thead>
          <tr>
            <th className="dn-header-top" scope="col" style={{ "background-color": "#e9ecef" }}></th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "#e9ecef" }}></th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "#e9ecef" }}>Date</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Apps</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Approvals</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Tier 1#</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Tier 2#</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Tier 3#</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Tier 4#</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Deals #</th>
            <th className="dn-header-top" scope="col" style={{ "background-color": "orange" }}>Deal GP $</th>
          </tr>
        </thead>
        <tbody>
          {months.map((month) => (
            <tr>
              {userData.roles !== "normal" && userData.roles !== "Radmin" &&
                <>
                  <td>
                    {edit[month] ?
                      <button
                        className="button"
                        onClick={() => { SaveRecord(month, budgetEntryFF) }}>
                        Save
                      </button>
                      :
                      <button className="button"
                        onClick={() => {
                          setEdit({ [month]: true });
                          store.dispatch(updateBudgetFFRow({ ...filterVal, ...budgetData[month], 'month': month }));
                        }}>
                        {budgetData[month] ? 'Edit' : 'Add'}
                      </button>
                    }
                  </td>
                  <td>
                    <button className="button" onClick={() => DeleteRecord(month)}>
                      Delete
                    </button>
                  </td>
                </>}

              <td className="dn-column">{month}</td>

              {creditMaxxTableFields.map(field => {
                if (field == 'deal_gp') {
                  return edit[month] ?
                    <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">
                      <input onChange={(e) => onChangeValue(e, field)} type="number" className="dn-input" value={budgetEntryFF[field]} />
                    </td>
                    : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">
                      {budgetData[month] && budgetData[month][field] ? '$' + parseInt(budgetData[month][field]).toLocaleString() : '$' + 0}
                    </td>
                } else {
                  return edit[month] ?
                    <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">
                      <input onChange={(e) => onChangeValue(e, field)} type="number" className="dn-input" value={budgetEntryFF[field]} />
                    </td>
                    : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">
                      {budgetData[month] && budgetData[month][field] ? budgetData[month][field] : 0}
                    </td>
                }
              })}
            </tr>
          ))}

          <tr>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}>Total</td>
            {creditMaxxTableFields.map(field => {
              if (field == 'deal_gp') {
                return <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                  {'$' + Object.values(budgetData).map(item => item[field] ? parseInt(item[field]) : 0).reduce((a, b) => a + b, 0).toLocaleString()}
                </td>
              } else {
                return <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                  {Object.values(budgetData).map(item => item[field] ? parseInt(item[field]) : 0).reduce((a, b) => a + b, 0).toLocaleString()}
                </td>
              }
            })}
          </tr>
        </tbody>
      </table>
    )
  }

  const OneEarthTable = () => {
    const budgetEntryFF = useSelector(state => state.dataEntryBudget.budgetEntryFF);
    const OneEarthTableFields = ["new_auto_ffun_adv", "new_auto_gap", "new_auto_creditor", "used_auto_ffun_adv", "used_auto_warranty", "used_auto_gap", "used_auto_creditor", "rec_ffun_adv", "rec_warranty", "rec_gap", "rec_creditor"];

    const getTotalAdv = (data = null) => {
      if (data) {
        let new_auto_ffun_adv = data.new_auto_ffun_adv ? parseInt(data.new_auto_ffun_adv) : 0;
        let used_auto_ffun_adv = data.used_auto_ffun_adv ? parseInt(data.used_auto_ffun_adv) : 0;
        let rec_ffun_adv = data.rec_ffun_adv ? parseInt(data.rec_ffun_adv) : 0;
        let sum = new_auto_ffun_adv + used_auto_ffun_adv + rec_ffun_adv;
        return sum;
      } else {
        return 0;
      }
    };

    const getTotalWarranty = (data = null) => {
      if (data) {
        let used_auto_warranty = data.used_auto_warranty ? parseInt(data.used_auto_warranty) : 0;
        let rec_warranty = data.rec_warranty ? parseInt(data.rec_warranty) : 0;
        let sum = used_auto_warranty + rec_warranty;
        return sum;
      } else {
        return 0;
      }
    };

    const getTotalGap = (data = null) => {
      if (data) {
        let new_auto_gap = data.new_auto_gap ? parseInt(data.new_auto_gap) : 0;
        let used_auto_gap = data.used_auto_gap ? parseInt(data.used_auto_gap) : 0;
        let rec_gap = data.rec_gap ? parseInt(data.rec_gap) : 0;
        let sum = new_auto_gap + used_auto_gap + rec_gap;
        return sum;
      } else {
        return 0;
      }
    };

    const getTotalCreditor = (data = null) => {
      if (data) {
        let new_auto_creditor = data.new_auto_creditor ? parseInt(data.new_auto_creditor) : 0;
        let used_auto_creditor = data.used_auto_creditor ? parseInt(data.used_auto_creditor) : 0;
        let rec_creditor = data.rec_creditor ? parseInt(data.rec_creditor) : 0;
        let sum = new_auto_creditor + used_auto_creditor + rec_creditor;
        return sum;
      } else {
        return 0;
      }
    };

    let total = { ffunAdv: [], warranty: [], gap: [], creditor: [] };
    months.map((month) => {
      total.ffunAdv.push(getTotalAdv(budgetData[month]));
      total.warranty.push(getTotalWarranty(budgetData[month]));
      total.gap.push(getTotalGap(budgetData[month]));
      total.creditor.push(getTotalCreditor(budgetData[month]));
    })

    return (
      <table class="table table-bordered border-black" style={{ marginTop: 30 }}>
        <thead>
          <tr className="text-center">
            <th colSpan="3" className="dn-header-top" style={{ "background-color": "#e9ecef" }}></th>
            <th colSpan="3" className="dn-header-top" style={{ "background-color": "orange" }}>New Auto</th>
            <th colSpan="4" className="dn-header-top" style={{ "background-color": "#A2C1A2" }}>Used Auto</th>
            <th colSpan="4" className="auto-header-top" style={{ "background-color": "#B1D5DD" }}>Rec</th>
            <th colSpan="4" className="auto-header-top" style={{ "background-color": "#ccc" }}>Total</th>
          </tr>
          <tr>
            <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}></th>
            <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}></th>
            <th className="dn-header" scope="col" style={{ "background-color": "#e9ecef" }}>Date</th>
            <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>OEPP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>GAP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "orange" }}>Creditor</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#A2C1A2" }}>OEPP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#A2C1A2" }}>Warranty</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#A2C1A2" }}>Gap</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#A2C1A2" }}>Creditor</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#B1D5DD" }}>OEPP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#B1D5DD" }}>Warranty</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#B1D5DD" }}>Gap</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#B1D5DD" }}>Creditor</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#ccc" }}>OEPP</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#ccc" }}>Warranty</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#ccc" }}>Gap</th>
            <th className="dn-header" scope="col" style={{ "background-color": "#ccc" }}>Creditor</th>
          </tr>
        </thead>
        <tbody>
          {months.map((month) => (
            <tr>
              {userData.roles !== "normal" && userData.roles !== "Radmin" &&
                <>
                  <td>
                    {edit[month] ?
                      <button
                        className="button"
                        onClick={() => { SaveRecord(month, budgetEntryFF) }}>
                        Save
                      </button>
                      :
                      <button className="button"
                        onClick={() => {
                          setEdit({ [month]: true });
                          store.dispatch(updateBudgetFFRow({ ...filterVal, ...budgetData[month], 'month': month }));
                        }}>
                        {budgetData[month] ? 'Edit' : 'Add'}
                      </button>
                    }
                  </td>
                  <td>
                    <button className="button" onClick={() => DeleteRecord(month)}>
                      Delete
                    </button>
                  </td>
                </>}
              <td className="dn-column">{month}</td>

              {OneEarthTableFields.map(field => (
                edit[month] ?
                  <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">
                    <input onChange={(e) => onChangeValue(e, field)} type="number" className="dn-input" value={budgetEntryFF[field]} />
                  </td>
                  : <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">
                    {budgetData[month] && budgetData[month][field] ? budgetData[month][field] : 0}
                  </td>
              ))}
              <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">{getTotalAdv(budgetData[month]).toLocaleString()}</td>
              <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">{getTotalWarranty(budgetData[month]).toLocaleString()}</td>
              <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">{getTotalGap(budgetData[month]).toLocaleString()}</td>
              <td style={{ borderColor: 'rgba(0, 0, 0, 0.05)' }} className="dn-column">{getTotalCreditor(budgetData[month]).toLocaleString()}</td>
            </tr>
          ))}

          <tr>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}></td>
            <td className="dn-column" scope="col" style={{ "background-color": "#e9ecef" }}>Total</td>
            {OneEarthTableFields.map(field => (
              <td style={{ "background-color": "#e9ecef" }} className="dn-column">
                {Object.values(budgetData).map(item => item[field] ? parseInt(item[field]) : 0).reduce((a, b) => a + b, 0).toLocaleString()}
              </td>
            ))}
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{total.ffunAdv.reduce((a, b) => a + b, 0).toLocaleString()}</td>
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{total.warranty.reduce((a, b) => a + b, 0).toLocaleString()}</td>
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{total.gap.reduce((a, b) => a + b, 0).toLocaleString()}</td>
            <td style={{ "background-color": "#e9ecef" }} className="dn-column">{total.creditor.reduce((a, b) => a + b, 0).toLocaleString()}</td>
          </tr>

        </tbody>
      </table>
    )
  }


  return (
    <div style={{ margin: "2rem 2rem 2rem 0rem" }}>
      <DealerYearMonthSelector stores={stores} filterVal={filterVal} setFilterVal={setFilterVal} loadData={() => loadData()} />

      {filterVal.store_name === "CreditMaxx" && <CreditMaxxTable />}
      {filterVal.store_name === "One Earth" && <OneEarthTable />}
    </div>
  )
}

export default BudgetFfunFinancial